import React, { useCallback, useEffect, useState } from "react";
import "./GlossaryMatches.scss";

import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { ReactComponent as AlertInfo } from "../../assets/alertInfo.svg";
import { ReactComponent as PlayIcon } from "../../assets/playIcon.svg";
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

import { ToastContainer, toast } from "react-toastify";
import { Box, Button, TextField, Tooltip, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addGlossarytoTheBook,
  applyGlossaryOnOccurrence,
  deleteGlossaryFromTheBook,
  getGlossaryAffectedParagraphs,
  removeAddedSpanTags,
  scrollOccurrenceContainer,
  updateGlossarytoTheBook,
} from "../../utils/glossaryUtils";
import {
  getUserNameFromEmail,
  scrollToHighlightedText,
  searchTextOverBook,
} from "../../utils/translationUtil";
import GlossaryScopePopup from "../glossaryScopePopup/GlossaryScopePopup";
import GlossaryWarningModal from "../glosssaryWarningModal/GlossaryWarningModal";
import AccordianGlossary from "../Accordian/AccordianGlossary";
import AddGlossaryForm from "../AddGlossaryForm/AddGlossaryForm";
import { useParams } from "react-router-dom";
import store from "../../App/store";
import GlossaryApplicationStatus from "../GlossaryApplicationStatus/GlossaryApplicationStatus";
import { setGlossaryAppliedData, setGlossaryScreenType, setIsGlossaryApplying } from "../../App/editor/editor.actions";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { cleanGlossaryApplicationData, removeGlossarApplicationDataViaOccurrrence } from "../../utils/firebaseActions";
import { useSaveTranslatedChapter } from "../../hooks/useReviewTranslation";
import LinearProgressBar from "../LinearProgressBar/LinearProgressBar";

const AddToGlossary = ({
  onClose,
  highlightedEnglish,
  chapter,
  handleCloseGlossary,
  getBookGlossaryData,
  handleApplyGlossary,
  handlePopoverVisibility,
  isPopoverOpen,
  setSelectedGlossary,
  selectedGlossary,
  sourceEditor,
  handleChapterChange,
  fetchTranslatedChapterPending,
  setOpenedGlossaryData,
  openedGlossaryData,
  setIsLayoutFixed,
  translateEditor,
  setTranslatedContent,
  setGlossarySectionToggle,
  individualScroll
}) => {
  // console.log("englishWord",englishWord)
  const params = useParams();
  const successHandler = (data) => null;

  const {
    data,
    mutate: saveEditedTranslatedChapter,
    error,
    isPending,
    isSuccess,
  } = useSaveTranslatedChapter({ successHandler });

  const { loggedInUserEmail } = useSelector((state) => state.main);
  const { isGlossaryApplying ,isTranslationContentEdited} = useSelector((state) => state.editor);
  const [isLoading, setIsLoading] = useState(false);
  const [showVariantInputFeild, setShowVariantInputFeild] = useState(false);
  const [deleteWarningData, setDeleteWarningData] = useState(null);
  const [highlightedEnglishText, setHighlightedEnglishText] = useState("");
  const [glossaryMatchesData, setGlossaryMatchesData] = useState([]);
  const [findOverBookData, setFindOverBookData] = useState(null);
  const [applicationProgress, setApplicationProgress] = useState({
    occuranceNo: 0,
    chapterNo: 0,
  });
  const [isFirstOccurence, setIsFirstOccurence] = useState(false);
  const [findGlossaryData, setFindGlossaryData] = useState({
    totalOccurances: 0,
    currentIteration: 0,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showGlossaryNotFoundWarning, setShowGlossaryNotFoundWarning] =
    useState(false);
  const [disableApplyButton, setDisableApplyButton] = useState(false)
  const dispatch = useDispatch();
  const { glossaryList: glossaryData,glossaryAppliedData,currentChapter } = useSelector((state) => state.editor);
  const [sectionType, setSectionType] = useState("glossaryMatches");
  const [highlightedTextGlossaryItem, setHighlightedTextGlossaryItem] = useState(false)
  const [updateIterationData, setUpdateIterationData] = useState({
    chapterNo: "",
    iterationNo: "",
  });

  const updateFindTextResults = async (englishWord) => {
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );

    let totalCountsOverBook = 0;
    let OverBookIterationNo = 0;
    let wordOccurrencesOverBook;
    if (!findOverBookData || findOverBookData?.englishWord !== englishWord) {
      const data =
        (await searchTextOverBook(
          params.id,
          englishWord,
          "false",
          "true",
          "english"
        )) ?? {};

      wordOccurrencesOverBook = data.word_occurrences;
      setFindOverBookData({
        englishWord: englishWord,
        wordOccurrencesOverBook,
        chapterWiseOccurancePara: data?.chapter_paragraph_occurrences,
      });
    } else {
      wordOccurrencesOverBook = findOverBookData?.wordOccurrencesOverBook;
    }

    for (const key in wordOccurrencesOverBook) {
      if (Number(key) !== chapter?.chapter_number)
        totalCountsOverBook += wordOccurrencesOverBook[key];

      if (Number(key) < chapter?.chapter_number) {
        OverBookIterationNo += wordOccurrencesOverBook[key];
      }
    }

    let currentChapterOccurances =
      findAndReplaceEditing?.state?.results?.length ?? 0;
    let currentChapterIteration =
      findAndReplaceEditing?.state?.highlightedOffset ?? 0;

    setFindGlossaryData({
      englishWord: englishWord,
      totalOccurances: totalCountsOverBook + currentChapterOccurances,
      currentIteration: currentChapterIteration + OverBookIterationNo,
    });
    console.log("its called",currentChapterIteration + OverBookIterationNo)
    scrollToHighlightedText("source",individualScroll);
    scrollOccurrenceContainer();
  };

  console.log("findGlossaryData",findGlossaryData)

  const isChaptersLastOccurances = (type) => {
    if (!findOverBookData?.wordOccurrencesOverBook) return;
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    let currentChapterOccurances =
      findAndReplaceEditing?.state?.results?.length ?? 0;
    let currentChapterIteration =
      findAndReplaceEditing?.state?.highlightedOffset ?? 0;

    const chapterArray = Object.keys(
      findOverBookData?.wordOccurrencesOverBook
    )?.sort((a, b) => Number(a) - Number(b));
    let currentChapterNo = store.getState().editor.currentChapter;
    let currentChapterIndex = chapterArray.findIndex(
      (chapterNo) => Number(chapterNo) === currentChapterNo
    );

    if (
      type === "next" &&
      currentChapterOccurances === currentChapterIteration &&
      currentChapterIndex + 1 < chapterArray.length
    ) {
      handleChapterChange(Number(chapterArray[currentChapterIndex + 1]));
      return true;
    }

    if (
      type === "prev" &&
      currentChapterIteration === 1 &&
      currentChapterIndex - 1 >= 0
    ) {
      handleChapterChange(Number(chapterArray[currentChapterIndex - 1]));
      setTimeout(() => {
        setIsFirstOccurence(true);
      });
      return true;
    }

    return false;
  };


  useEffect(() => {
    if(Object.keys(glossaryAppliedData).length){
      document.documentElement.style.setProperty(
        `--glossary-highlight-transparent-bg`,
        "#3B37FF33"
      );
      
    }else{
      document.documentElement.style.setProperty(
        `--glossary-highlight-transparent-bg`,
        "transparent"
      );
      removeGlossaryChangesFromTranslatedChapter()
    }
  }, [glossaryAppliedData])
  

  const findWordInSourceEditor = (operation, englishText, isFirstOccurence) => {
    if (fetchTranslatedChapterPending) return;

    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    findAndReplaceEditing?.state?.set("matchWholeWords", true);

    if (isFirstOccurence) {
      setTimeout(() => {
        findAndReplaceEditing?.state?.set(
          "highlightedResult",
          findAndReplaceEditing?.state?.results?.get(
            findAndReplaceEditing?.state?.results?.length - 1 ?? 0
          )
        );
        updateFindTextResults(englishText);
      },10);
      setIsFirstOccurence(false);
      return;
    }
    console.log("FindAndReplaceEditing",findAndReplaceEditing)
    findAndReplaceEditing.find(englishText,{searchText:englishText,matchCase:false,wholeWords:true})
  };

  useEffect(() => {
    if (chapter && highlightedEnglishText && !fetchTranslatedChapterPending) {
      const finalEnglishWord = highlightedEnglish.text || highlightedEnglishText;
      if (
        // !newFilteredData.length && 
        !updateIterationData.chapterNo) {
        console.log("debug not required",highlightedEnglish.text,isFirstOccurence,updateIterationData)
        findWordInSourceEditor("find", finalEnglishWord,isFirstOccurence);
        updateFindTextResults(finalEnglishWord);
        // if (!showVariantInputFeild && highlightedEnglish.text)
        //   setShowGlossaryNotFoundWarning(true);
      }


      if (updateIterationData.chapterNo) {
        console.log("debug updateIterationData",updateIterationData,highlightedEnglish)
        const findAndReplaceEditing = sourceEditor.plugins.get(
          "FindAndReplaceEditing"
        );
  
        setTimeout(() => {
          findAndReplaceEditing?.state?.set(
            "highlightedResult",
            findAndReplaceEditing?.state?.results?.get(
              updateIterationData.iterationNo - 1 ?? 0
            )
          );
          updateFindTextResults(finalEnglishWord);
        },10);
        setUpdateIterationData({ chapterNo: "", iterationNo: "" });
        return ;
      }
    }
  }, [
    highlightedEnglishText,
    fetchTranslatedChapterPending,
  ]);

  // useEffect(() => {
  //   if (
  //     // !newFilteredData.length && 
      
  //     !updateIterationData.chapterNo) {
  //       const finalEnglishWord = highlightedEnglish.text || highlightedEnglishText;
  //     console.log("debug not required",highlightedEnglish.text,isFirstOccurence,updateIterationData)
  //     findWordInSourceEditor("find", finalEnglishWord,isFirstOccurence);
  //     updateFindTextResults(finalEnglishWord);      
  //     // if (!showVariantInputFeild && highlightedEnglish.text)
  //     //   setShowGlossaryNotFoundWarning(true);
  //   }
  // }, [highlightedEnglish])
  

  useEffect(() => {
    if (highlightedEnglish.text || highlightedEnglishText) {
      let finalEnglishWord = highlightedEnglish.text || highlightedEnglishText;
      setHighlightedEnglishText(finalEnglishWord);
      const lowercasedSearchTerm = finalEnglishWord.trim().toLowerCase();
      let glossaryExactMatch=""
      const newFilteredData = glossaryData.filter((glossary) => {
        if (
          glossary.english_word.toLocaleLowerCase().trim() ===
          lowercasedSearchTerm
        ){
          setOpenedGlossaryData(glossary);
          glossaryExactMatch=glossary
          return false
        }
          
        if (
          glossary.english_word.toLowerCase().includes(lowercasedSearchTerm) ||
          lowercasedSearchTerm.includes(glossary.english_word.toLowerCase())
        )
          return true;

        let isVariantFound = false;
        glossary.dutch_word_array.forEach((variant) => {
          if (
            variant.replacement_dutch_word
              .toLowerCase()
              .includes(lowercasedSearchTerm)
          )
            isVariantFound = true;
        });

        return isVariantFound;
      });
      setGlossaryMatchesData(newFilteredData);
      document.documentElement.style.setProperty(
        `--glossary-highlight-bg`,
        "#3B37FF33"
      );
      // document.documentElement.style.setProperty(
      //   `--glossary-highlight-transparent-bg`,
      //   "transparent"
      // );

      if(glossaryExactMatch?.english_word){
        setHighlightedTextGlossaryItem(glossaryExactMatch)
      }else{
        setShowGlossaryNotFoundWarning(true);
        setHighlightedTextGlossaryItem({})
      }
      console.log("glossaryExactMatch",glossaryExactMatch)
     
    }
  }, [highlightedEnglish, glossaryData]);

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));
   
   
  const removeGlossaryChangesFromTranslatedChapter= async()=>{
    try {
      if(isTranslationContentEdited) return ;
        const updatedChapter=removeAddedSpanTags(translateEditor)
        if(!updatedChapter) return ;
        setTranslatedContent(updatedChapter)
        const store = translateEditor.config.get("store").getState();
        setIsLayoutFixed(false)
        saveEditedTranslatedChapter({
          translation_id: params.id,
          chapter: store.editor.currentChapter,
          translated_data: updatedChapter,
        });
    } catch (error) {
      console.log("error in saving new chapter")
    }
  }



  const getNewVariant = (inputData, bookInfo) => {
    return {
      ...bookInfo,
      metadata_storage: {
        "creation date": new Date(),
        creation_user: loggedInUserEmail,
        application_history: [],
      },
      instructions: {
        whenToApply: inputData.whenToApply,
        exception: inputData.exceptions,
      },
      variant_scope: inputData.scope,
      replacement_dutch_word: inputData?.replacement_dutch_word?.trim(),
    };
  };

  const isVariantAlreadyExists = (variant, variantList) => {
    let isThisVariantAlreadyExists = variantList?.find(
      (dutchVariant) =>
        dutchVariant?.replacement_dutch_word?.toLowerCase() ===
        variant?.trim()?.toLocaleLowerCase()
    );

    if (isThisVariantAlreadyExists) {
      toast(`Variant already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return true;
    }

    return false;
  };


  const handleUpdateGlossary = async ({
    action,
    inputData,
    englishText,
    variant,
    type,
  }) => {
    if (isLoading) return;
    let toSearchEnglishWord = englishText;
    const isCurrentGlossaryExists = glossaryData.find(
      (glossary) =>
        glossary?.english_word?.toLowerCase() ===
        toSearchEnglishWord?.toLowerCase()
    );

    let updatedDutchVariants = isCurrentGlossaryExists?.dutch_word_array?.length
      ? [...isCurrentGlossaryExists?.dutch_word_array]
      : [];
    const bookInfo = {
      author: chapter.book_info.author_name,
      language: chapter?.translation_info?.translated_to?.toLowerCase(),
      genre: chapter.book_info.genre,
    };

    switch (action) {
      case "add":
        if (!inputData.replacement_dutch_word.trim()) {
          toast(`Input feild cannot be empty`, {
            type: "warning",
            autoClose: 3000,
          });
          return;
        }

        if (!isCurrentGlossaryExists || isCurrentGlossaryExists === -1) {
          setIsLoading(true);
          const glossaryPayload = {
            unique_string: chapter.translation_id,
            glossary_item: {
              ...bookInfo,
              english_word: englishText,
              dutch_word_array: [getNewVariant(inputData, bookInfo)],
              glossary_item_scope: "Book",
              application_scope: "book_level",
              chapter_numbers: [],
            },
          };
          await addGlossarytoTheBook(glossaryPayload);
          setShowVariantInputFeild(false);
          getBookGlossaryData();
          setIsLoading(false);
          return;
        }

        if (
          isCurrentGlossaryExists &&
          !isVariantAlreadyExists(
            inputData.replacement_dutch_word,
            isCurrentGlossaryExists.dutch_word_array
          )
        ) {
          updatedDutchVariants.unshift(getNewVariant(inputData, bookInfo));
        } else {
          return;
        }
        break;
      case "update":
        if (!inputData.replacement_dutch_word.trim()) {
          toast(`Input feild cannot be empty`, {
            type: "warning",
            autoClose: 3000,
          });
          return;
        }

        updatedDutchVariants = updatedDutchVariants.map((dutchVariant) => {
          if (dutchVariant.replacement_dutch_word === variant) {
            dutchVariant.replacement_dutch_word =
              inputData.replacement_dutch_word.trim();
            dutchVariant.variant_scope = inputData.scope;
            dutchVariant.instructions.whenToApply =
              inputData.whenToApply.trim();
            dutchVariant.instructions.exception = inputData?.exceptions;
          }
          return dutchVariant;
        });
        break;
      case "delete":
        if (!updatedDutchVariants.length) return;

        updatedDutchVariants = updatedDutchVariants.filter(
          (dutchVariant) => dutchVariant?.replacement_dutch_word !== variant
        );

        if (!updatedDutchVariants.length || type === "glossaryItem") {
          await deleteGlossaryFromTheBook(isCurrentGlossaryExists.id);
          if (
            selectedGlossary.englishWord ===
            isCurrentGlossaryExists.english_word
          )
            setSelectedGlossary({ englishWord: "", variant: "" });

          return;
        }
        break;
      default:
        break;
    }

    const glossaryPayload = {
      ...bookInfo,
      english_word: isCurrentGlossaryExists?.english_word,
      dutch_word_array: updatedDutchVariants,
      glossary_item_scope: "Book",
      application_scope: "book_level",
      chapter_numbers: [],
      is_active: true,
      is_part_of_collection: false,
      application_history: null,
    };

    await updateGlossarytoTheBook(glossaryPayload, isCurrentGlossaryExists.id);

    setShowVariantInputFeild(false);
    return updatedDutchVariants;
  };


 

  const handleDeleteGlossaryThroughPopup = () => {
    if (deleteWarningData.type === "Glossary") {
      handleUpdateGlossary("delete", "dummyVariant", "glossaryItem");
    } else {
      handleUpdateGlossary("delete", deleteWarningData.variant, "variant");
    }

    setDeleteWarningData(null);
  };


  

  const handleCloseGlossaryPannel =  async () => {
    if (isLoading || isGlossaryApplying.status) return;
   
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );

    findAndReplaceEditing?.state.clear(sourceEditor.model);
    findAndReplaceEditing.stop();
    removeGlossaryChangesFromTranslatedChapter()
    dispatch(setGlossaryScreenType(""))
    document.documentElement.style.removeProperty(
      `--glossary-highlight-bg`,
      "#3B37FF33"
    );
    setFindOverBookData(null);
    document.documentElement.style.removeProperty(
      `--glossary-highlight-transparent-bg`,
      "transparent"
    );

    document.documentElement.style.removeProperty(
      `--glossary-changes-bg`,
      "#3B37FF26"
    );
    setGlossarySectionToggle(false)
    cleanGlossaryApplicationData(params.id)
    dispatch(setIsGlossaryApplying({status:false,englishWord:"",operationType:"",occurrenceNo:""}))

    setFindOverBookData({
      englishWord: "",
      wordOccurrencesOverBook: "",
      chapterWiseOccurancePara: "",
    });
    setFindGlossaryData({
      englishWord: "",
      totalOccurances: "",
      currentIteration: "",
    });
  };


  const getTotalGlossaryLenth=()=>{
    let length=glossaryMatchesData.length
    length += highlightedTextGlossaryItem?.english_word ? 1 : 0 
    return length
  }

  const getWordOccurrenceNo=(currentChapterOccurenceNo,wordOccurrencesData,chapterNo)=>{
    let occurrenceNo=0
    Object.keys(wordOccurrencesData).forEach((chapterKey)=>{
      let totalOccurances=wordOccurrencesData[chapterKey]
      if(Number(chapterKey)<chapterNo)
        occurrenceNo+=totalOccurances
      
      if(Number(chapterKey)===chapterNo)
        occurrenceNo+=currentChapterOccurenceNo
    })

    return occurrenceNo
  }

  const handleFixGlossary=async()=>{
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    const currentChapterOccurenceNo=findAndReplaceEditing.state.highlightedOffset;
    const wordOccurrenceNo=getWordOccurrenceNo(currentChapterOccurenceNo,findOverBookData.wordOccurrencesOverBook,currentChapter)
    const paragraphs = document.querySelectorAll('#source .ck-editor__editable > p');
    let englishParagraph=""   
    paragraphs.forEach((paragraph)=>{
         const spanElement=paragraph.querySelector(".ck-find-result_selected")
         if(spanElement){
          englishParagraph=paragraph.outerHTML
         }
    })
     
    console.log("fixOccurrence check",englishParagraph,wordOccurrenceNo,findOverBookData.wordOccurrencesOverBook)
    if(!englishParagraph || !wordOccurrenceNo || !openedGlossaryData) return
    let updatedGlossaryAppliedData=glossaryAppliedData
    removeGlossarApplicationDataViaOccurrrence(wordOccurrenceNo,params.id)
    dispatch(setIsGlossaryApplying({status:true,englishWord:findOverBookData.englishWord,operationType:"fix",occurrenceNo:wordOccurrenceNo}))
    setSectionType("applicationStatus");
    const payload={
      unique_string:params.id,
      glossary_id:openedGlossaryData.id,
      chapter_id:`chapter_${currentChapter}`,
      original_english_paragraph_with_html:englishParagraph,
      variant_index:0,
      applied_by:loggedInUserEmail,
      application_source:"glossary",
      variant_selection_type:"full_context_type",
      word_occurrence_number:wordOccurrenceNo
    }
    console.log("fixOccurrence",payload)
    await applyGlossaryOnOccurrence(payload)
    if (!isChaptersLastOccurances("next") && findGlossaryData?.currentIteration !==
    findGlossaryData?.totalOccurances) {
      sourceEditor.execute("findNext");
      updateFindTextResults(findGlossaryData.englishWord);
      // scrollOccurrenceContainer();
    }
    dispatch(setIsGlossaryApplying({status:false,englishWord:findOverBookData.englishWord,operationType:'fix',occurrenceNo:wordOccurrenceNo}))    
  }

  const checkFixOperationType = () => {
    const applicationData =
      glossaryAppliedData?.[
        findOverBookData?.englishWord || highlightedEnglishText
      ];

    if (!applicationData) return;
    let isPending = false;
    let isApplied = false;
    Object.keys(applicationData?.affectedChaptersData || {})?.forEach(
      (occuranceNo) => {
        if (
          applicationData?.affectedChaptersData[occuranceNo].status ===
          "applied"
        )
          isApplied = true;

        if (
          applicationData?.affectedChaptersData[occuranceNo].status ===
          "pending"
        )
          isPending = true;
      }
    );

    if (isPending && isApplied) return "fix";

    if (!isPending && isApplied) return "fixAll";
  };

  return (
    <>
      <div
        style={{ height: "calc(100dvh - 133px - 71px)" }}
        className={`w-[576px] shrink-0 top-[100px] left-4 glossaryContainer fixed  gap-2 flex 
            "pb-[67.46px]" 
           flex-col px-3 bg-[#FFFFFF] my-12  rounded-md`}
      >
        <ToastContainer hideProgressBar={true} />
        {sectionType === "glossaryMatches" && (
          <>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <span className="text-sm text-[#1E1C80]">
                  Glossary entries for "{highlightedEnglishText}" (
                  {getTotalGlossaryLenth()})
                </span>
              </div>
              <div>
                <CloseIcon
                  className="cursor-pointer"
                  onClick={handleCloseGlossaryPannel}
                />
              </div>
            </div>
            {/* <div className="text-[#5F6368] text-sm font-[Poppins]">
              Match found ({glossaryMatchesData.length})
            </div> */}
            {!highlightedTextGlossaryItem?.english_word &&
              showGlossaryNotFoundWarning && (
                <div
                  className="flex gap-[22px] text-sm items-start rounded-[4px] py-3 px-4 bg-[#FFFBE6]"
                  style={{ border: "1px solid #FFE58F" }}
                >
                  <div className="flex gap-2 items-start">
                    <AlertInfo />
                    <span className="-mt-1">
                      No translations found for "{highlightedEnglishText}". You
                      can add a glossary entry below
                    </span>
                  </div>
                  <CloseIcon
                    onClick={() => setShowGlossaryNotFoundWarning(false)}
                    className="cursor-pointer text-base text-[#8C8A7E]"
                  />
                </div>
              )}
            <div
              style={{
                height: 
                // highlightedTextGlossaryItem?.english_word ? "calc(100dvh - 133px - 170px)" : 
                showGlossaryNotFoundWarning
                  ?  "calc(100dvh - 133px - 179px - 62px)":
                   "calc(100dvh - 133px - 170px)",
              }}
              className="flex flex-col overflow-y-scroll pb-2 hideSourceScrollBar gap-2"
            >
              {!showVariantInputFeild &&
                !highlightedTextGlossaryItem?.english_word && (
                  <div
                    onClick={() => {
                      setShowVariantInputFeild(true);
                      setShowGlossaryNotFoundWarning(false);
                    }}
                    className="flex flex-col mb-2 gap-3"
                  >
                    <div className="py-1 bg-[#F5F7FF]  px-2 rounded-[4px]">
                      {highlightedEnglishText}
                    </div>
                    <div className="py-2 text-[12px] text-[#3B37FF] rounded-[4px]  h-[211px] bg-[#F8F9FC] px-2 cursor-pointer flex items-center justify-center gap-1">
                      <AddGlossaryIcon className="text-[#3B37FF]" />
                      Add translation
                    </div>
                  </div>
                )}
              {showVariantInputFeild && (
                <div className="bg-[#F8F9FC] flex flex-col px-3 mb-2 gap-2 rounded-[4px]">
                  <div className="py-1 pt-2 rounded-[4px]">
                    {highlightedEnglishText}
                  </div>
                  <AddGlossaryForm
                    chapter={chapter}
                    setShowVariantInputFeild={setShowVariantInputFeild}
                    setGlossaryMatchesData={setGlossaryMatchesData}
                    setOpenedGlossaryData={setOpenedGlossaryData}
                    englishWord={highlightedEnglishText}
                    glossaryMatchesData={glossaryMatchesData}
                    handleUpdateGlossary={handleUpdateGlossary}
                    operationType={"add"}
                    setSelectedGlossary={setSelectedGlossary}
                  />
                  <div className="py-2 text-[12px] text-[#3B37FF] cursor-pointer flex items-center justify-start gap-1">
                    <AddGlossaryIcon className="text-[#3B37FF]" />
                    Add translation
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-2">
                {highlightedTextGlossaryItem?.english_word && (
                  <div className="mb-1">
                    <AccordianGlossary
                      openedGlossaryData={openedGlossaryData}
                      glossary={highlightedTextGlossaryItem}
                      chapter={chapter}
                      setGlossaryMatchesData={setGlossaryMatchesData}
                      glossaryMatchesData={glossaryMatchesData}
                      setOpenedGlossaryData={setOpenedGlossaryData}
                      sourceEditor={sourceEditor}
                      handleUpdateGlossary={handleUpdateGlossary}
                      setSelectedGlossary={setSelectedGlossary}
                      selectedGlossary={selectedGlossary}
                      glossaryData={glossaryData}
                      updateFindTextResults={updateFindTextResults}
                      highlightedEnglishText={highlightedEnglishText}
                    />
                  </div>
                )}
                {glossaryMatchesData.length > 0 && (
                  <div className="text-[#1E1C80] -mb-2 text-sm">
                    Similar Glossary items ({glossaryMatchesData.length})
                  </div>
                )}
                <div className="flex flex-col gap-2">
                  {glossaryMatchesData.map((glossary) => {
                    return (
                      <AccordianGlossary
                        openedGlossaryData={openedGlossaryData}
                        glossary={glossary}
                        chapter={chapter}
                        setGlossaryMatchesData={setGlossaryMatchesData}
                        glossaryMatchesData={glossaryMatchesData}
                        setOpenedGlossaryData={setOpenedGlossaryData}
                        sourceEditor={sourceEditor}
                        handleUpdateGlossary={handleUpdateGlossary}
                        setSelectedGlossary={setSelectedGlossary}
                        selectedGlossary={selectedGlossary}
                        glossaryData={glossaryData}
                        updateFindTextResults={updateFindTextResults}
                        highlightedEnglishText={highlightedEnglishText}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
        {sectionType === "applicationStatus" && (
          <GlossaryApplicationStatus
            handleCloseGlossaryPannel={handleCloseGlossaryPannel}
            sectionType={sectionType}
            setSectionType={setSectionType}
            highlightedEnglishText={highlightedEnglishText}
            findOverBookData={findOverBookData}
            sourceEditor={sourceEditor}
            findGlossaryData={findGlossaryData}
            updateFindTextResults={updateFindTextResults}
            handleChapterChange={handleChapterChange}
            fetchTranslatedChapterPending={fetchTranslatedChapterPending}
            setDisableApplyButton={setDisableApplyButton}
            disableApplyButton={disableApplyButton}
            translateEditor={translateEditor}
            openedGlossaryData={openedGlossaryData}
            setApplicationProgress={setApplicationProgress}
            applicationProgress={applicationProgress}
            setShowLoader={setShowLoader}
            showLoader={showLoader}
            chapter={chapter}
            setUpdateIterationData={setUpdateIterationData}
          />
        )}
        {showLoader && (
          <div className="absolute left-0 bottom-[52px] w-full">
            <LinearProgressBar
              value={
                (100 / findGlossaryData?.totalOccurances) *
                applicationProgress.occuranceNo
              }
            />
          </div>
        )}
        <div
          style={{ boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.05)" }}
          className="footer py-2 flex justify-between items-center right-0 px-3   absolute bottom-0 w-full "
        >
          <div
            onClick={() => {
              if (!findGlossaryData?.totalOccurances) return;
              setSectionType("applicationStatus");
            }}
            className="text-sm flex gap-[6px] items-center cursor-pointer text-[#1E1C80] font-[poppins] font-[500]"
          >
            {sectionType === "glossaryMatches" && (
              <>
                {" "}
                {/* <PlayIcon /> */}
                {isGlossaryApplying.status &&
                isGlossaryApplying.operationType === "fixAll" ? (
                  <span className="underline">
                    Applying{" "}
                    {findGlossaryData?.totalOccurances > 1 ? "Fixes" : "Fix"} (
                    {findGlossaryData?.totalOccurances})
                  </span>
                ) : glossaryAppliedData?.[
                    findOverBookData?.englishWord || highlightedEnglishText
                  ] && isGlossaryApplying.operationType === "fixAll" ? (
                  <span className="underline">
                    Applied{" "}
                    {findGlossaryData?.totalOccurances > 1 ? "Fixes" : "Fix"} (
                    {findGlossaryData?.totalOccurances})
                  </span>
                ) : (
                  <span className="underline">
                    Recommended{" "}
                    {findGlossaryData?.totalOccurances > 1 ? "Fixes" : "Fix"} (
                    {findGlossaryData?.totalOccurances})
                  </span>
                )}
              </>
            )}
          </div>
          <div className="flex gap-[15px] items-center">
            <Button
              disabled={
                isGlossaryApplying.status
                  ? true
                  : findGlossaryData?.currentIteration <= 1
              }
              className="cursor-pointer py-[6px] px-0 gap-1 flex items-center"
              onClick={() => {
                if (!isChaptersLastOccurances("prev")) {
                  sourceEditor.execute("findPrevious");
                  updateFindTextResults(findGlossaryData.englishWord);
                }
              }}
            >
              <ExpandLessRoundedIcon />
              Previous
            </Button>
            <Button
              disabled={
                isGlossaryApplying.status
                  ? true
                  : findGlossaryData?.currentIteration ===
                    findGlossaryData?.totalOccurances
              }
              className="cursor-pointer py-[6px] px-0 gap-1 flex"
              onClick={() => {
                if (!isChaptersLastOccurances("next")) {
                  sourceEditor.execute("findNext");
                  updateFindTextResults(findGlossaryData.englishWord);
                }
              }}
            >
              Next
              <ExpandMoreRoundedIcon />
            </Button>
            <Button
              onClick={handleFixGlossary}
              variant="outlined"
              disabled={
                ((isGlossaryApplying?.englishWord===findOverBookData?.englishWord) && isGlossaryApplying.status || isGlossaryApplying.operationType==="fixAll")
                  ? true
                  : openedGlossaryData.english_word
                  ? false
                  : true
              }
              className="flex rounded-[4px] relative text-[12px] py-[6px] px-3 gap-2"
            >
              <div className="flex items-center gap-2">
                {isGlossaryApplying.status &&
                  isGlossaryApplying.operationType === "fix" && (
                    <CircularProgress size="16px" color="inherit" />
                  )}
                Fix
              </div>
            </Button>
            <div className="relative">
              <Button
                disabled={
                  ((isGlossaryApplying?.englishWord===findOverBookData?.englishWord) && isGlossaryApplying.status || isGlossaryApplying.operationType==="fix")
                    ? true
                    : disableApplyButton
                    ? true
                    : openedGlossaryData.english_word
                    ? false
                    : true
                }
                onClick={() => {
                  dispatch(setGlossaryAppliedData({}));
                  cleanGlossaryApplicationData(params.id);
                  handleApplyGlossary("fixAll");
                  setSectionType("applicationStatus");
                }}
                variant="contained"
                className="flex rounded-[4px] fixAll relative text-[12px] py-[6px] px-3 gap-2"
              >
                <div className="flex items-center gap-2">
                  {showLoader ? (
                    <span>
                      Fix {applicationProgress.occuranceNo}/
                      {findGlossaryData.totalOccurances}
                    </span>
                  ) : (
                    <span>Fix All</span>
                  )}
                  {isGlossaryApplying.status &&
                    isGlossaryApplying.operationType === "fixAll" && (
                      <CircularProgress size="16px" color="inherit" />
                    )}
                </div>
              </Button>
              {/* {isPopoverOpen && (
              <GlossaryScopePopup
                handleApplyGlossary={handleApplyGlossary}
                handlePopoverVisibility={handlePopoverVisibility}
              />
            )} */}
            </div>
          </div>
        </div>
        {deleteWarningData && (
          <GlossaryWarningModal
            deleteWarningData={deleteWarningData}
            closePopup={() => setDeleteWarningData(null)}
            handleDelete={handleDeleteGlossaryThroughPopup}
          />
        )}
      </div>
    </>
  );
};

export default AddToGlossary;
