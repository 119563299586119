import React, { useEffect, useState } from "react";
import { ReactComponent as LeftChevron } from "../../assets/leftChevron.svg";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import { ReactComponent as CircleExclamation } from "../../assets/circleExclamation.svg";
import { ReactComponent as Stars } from "../../assets/stars.svg";

import { ReactComponent as EditPencil } from "../../assets/editPencil.svg";
import { cn } from "../../lib/utils";
import "./GlossaryApplicationStatus.scss";
import store from "../../App/store";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  LinearProgress,
  linearProgressClasses,
  styled,
  Tooltip,
} from "@mui/material";
import EditTranslation from "../EditTranslation/EditTranslation";
import GlossaryAffectedSentence from "../GlossaryAffectedSentence/GlossaryAffectedSentence";
import { removeGlossarApplicationDataViaOccurrrence } from "../../utils/firebaseActions";
function GlossaryApplicationStatus({
  sectionType,
  setSectionType,
  highlightedEnglishText,
  handleCloseGlossaryPannel,
  findOverBookData,
  sourceEditor,
  findGlossaryData,
  updateFindTextResults,
  handleChapterChange,
  fetchTranslatedChapterPending,
  setDisableApplyButton,
  disableApplyButton,
  translateEditor,
  openedGlossaryData,
  setApplicationProgress,
  applicationProgress,
  showLoader,
  setShowLoader,
  chapter,
  setUpdateIterationData
}) {
  // const [updateIterationData, setUpdateIterationData] = useState({
  //   chapterNo: "",
  //   iterationNo: "",
  // });
  const { glossaryAppliedData, isGlossaryApplying ,currentChapter} = useSelector(
    (state) => state.editor
  );
  const [occurrencesListData, setOccurrencesListData] = useState({});
  const [glossaryApplicationStatus, setGlossaryApplicationStatus] = useState(
    {}
  );
  // const [applicationProgress, setApplicationProgress] = useState({
  //   occuranceNo: 0,
  //   chapterNo: 0,
  // });
  const [replaceTranslationLoader, setReplaceTranslationLoader] = useState(false)

  const [occurrenceNoToEdit, setOccurrenceNoToEdit] = useState(0)
  const [expanded, setExpanded] = useState(Object?.keys(findOverBookData?.wordOccurrencesOverBook)); 

  // useEffect(() => {
  //   if (!fetchTranslatedChapterPending && updateIterationData.chapterNo) {
  //     console.log("debug updateIterationData",updateIterationData)
  //     const findAndReplaceEditing = sourceEditor.plugins.get(
  //       "FindAndReplaceEditing"
  //     );

  //     setTimeout(() => {
  //       findAndReplaceEditing?.state?.set(
  //         "highlightedResult",
  //         findAndReplaceEditing?.state?.results?.get(
  //           updateIterationData.iterationNo - 1 ?? 0
  //         )
  //       );
  //     }, 10);
  //     setUpdateIterationData({ chapterNo: "", iterationNo: "" });
  //   }
  // }, [fetchTranslatedChapterPending]);

  const handleRedirection = (iteration, chapterNo) => {
    console.log("debug redirection",iteration,chapterNo,findGlossaryData)
    let currentChapterNo = store.getState().editor.currentChapter;
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );

    if (currentChapterNo === chapterNo) {
      if (findGlossaryData.currentIteration === getOccuranceNo(Number(chapterNo),iteration)) return;
      findAndReplaceEditing?.state?.set(
        "highlightedResult",
        findAndReplaceEditing?.state?.results?.get(iteration - 1 ?? 0)
      );
      updateFindTextResults(findOverBookData.englishWord);
      return;
    }

    handleChapterChange(chapterNo);
    setUpdateIterationData({ chapterNo: chapterNo, iterationNo: iteration });
  };


  useEffect(() => {
    const getHighlightedTextSentence = () => {
      let chapterWiseOccuranceData = findOverBookData?.chapterWiseOccurancePara;
      if (!chapterWiseOccuranceData) return;
      // const allTranslatedParagraphs = document.querySelectorAll(
      //   `#source .ck-editor__editable > p`
      // );
      const finalData = {};
      let occuranceNo = 0;
      Object.keys(chapterWiseOccuranceData).forEach((chapterNo) => {
        let resultTexts = [];
        let textContainsParagraph =
          chapterWiseOccuranceData?.[chapterNo]?.English || [];
        console.log(
          "chapterWiseOccuranceData",
          textContainsParagraph,
          chapterWiseOccuranceData
        );

        textContainsParagraph?.forEach((paragraph) => {
          // Get the full rendered text of the paragraph
          const parser = new DOMParser();
          const paragraphElement = parser.parseFromString(
            paragraph,
            "text/html"
          );
          const fullText = paragraphElement?.body?.textContent || "";

          const sentences = fullText.match(/[^.!?…—]*(?:[.!?…—]+|$)/g);

          sentences?.forEach((sentence) => {
            // Regex to find all occurrences of the word in the current sentence
            const regex = new RegExp(
              `\\b${findOverBookData?.englishWord || highlightedEnglishText}\\b`,
              "gi"
              // `(?<!\\w)${findOverBookData?.englishWord || highlightedEnglishText}(?!\\w)`,
              // "gi"
            );
  
            const matches = [...sentence.matchAll(regex)];
  
            // For each occurrence, create a new highlighted sentence
            matches.forEach((match) => {
              let highlightedSentence = sentence.replace(
                regex,
                (m, i) => (i === match.index ? `<span class="highlightText">${m}</span>` : m)
              );
  
              occuranceNo++;
              resultTexts.push({
                occuranceNo: occuranceNo,
                englishText: highlightedSentence,
                latestTranslatedText: "",
              });
            });
          });

          // const regex = new RegExp(
          //   `\\b${findOverBookData?.englishWord || highlightedEnglishText}\\b`,
          //   "gi"
          // );
          // const matches = [...fullText.matchAll(regex)];
          // console.log("resultTexts", highlightedEnglishText, matches);
          // matches.forEach((match) => {
          //   // Get the position of "the"
          //   const position = match.index;

          //   // Get the part of the paragraph before "the"
          //   const beforeThe = fullText.slice(0, position).trim();
          //   const afterThe = fullText.slice(position + match[0].length).trim();
          //   const wordsAfter = afterThe.split(/\s+/);

          //   // Split it into words
          //   const wordsBefore = beforeThe.split(/\s+/);

          //   // Get the last three words before "the"
          //   const lastThreeWords = wordsBefore.slice(-3).join(" ");
          //   const nextThreeWords = wordsAfter.slice(0, 3).join(" ");

          //   occuranceNo++;
          //   resultTexts.push({
          //     occuranceNo: occuranceNo,
          //     englishText:
          //       lastThreeWords +
          //       ` <span class="highlightText">${match[0]}</span> ` +
          //       nextThreeWords,
          //     latestTranslatedText: "",
          //   });
          // });
        });
        finalData[chapterNo] = resultTexts;
      });
      console.log("resultTexts", finalData);
      setOccurrencesListData(finalData);
    };

    getHighlightedTextSentence();
  }, [findOverBookData]);

  const getOccuranceStatus = (occuranceStatus, aiStatus) => {
    let finalStatus="";
    
    switch(aiStatus){
      case "applied":
        return "Applied"
      case "fix_not_applied":
        return "Skipped"
      case "ai_suggested":
        return "AiSuggested"
    }

    switch (occuranceStatus) {
      case "applying":
        return "Applying";
      case "pending":
        return "Pending";
      case "skipped":
        return "Skipped";
      case "applied":
        // if(aiStatus !=='pending')
        //   return aiStatus
        return "Applied";
      default :
        return "NotApplied"
    }
  };


   const getChapterApplicationStatus = (applicationStatus) => {
    let chapterCount=0;
    Object.keys(applicationStatus).forEach((chapterNo)=>{
      if (!applicationStatus[chapterNo] || applicationStatus?.[chapterNo]?.[1] === "NotApplied") return;
      let status="Applied"
      Object?.keys(applicationStatus?.[chapterNo])?.forEach((occuranceNo) => {
        if (applicationStatus?.[chapterNo]?.[occuranceNo] === "Pending" || applicationStatus?.[chapterNo]?.[occuranceNo] === "Applying")
          status= "Pending";
      });

      if(status==="Applied")
        chapterCount++;
    })

    return chapterCount;
  
  };

  useEffect(() => {
    if (isGlossaryApplying.status) {
      if(isGlossaryApplying.operationType==="fixAll"){
        setShowLoader(true);
        setDisableApplyButton(true);
      }
    }

    let applicationStatus = {};
    let currentGlossaryData =
      glossaryAppliedData?.[
        findOverBookData?.englishWord || highlightedEnglishText
      ] || {};
    let occuranceNo = 0;
    Object?.keys(findOverBookData?.wordOccurrencesOverBook)?.forEach(
      (chapterNo,i) => {
        let occurencesData = {};
        for (
          let i = 1;
          i <= findOverBookData?.wordOccurrencesOverBook[chapterNo];
          i++
        ) {
          occuranceNo++;
          if(isGlossaryApplying.operationType==="fixAll" && occuranceNo===1 && isGlossaryApplying.status){
            occurencesData[occuranceNo]="Applying"
          }else if(isGlossaryApplying.occurrenceNo===occuranceNo){
            occurencesData[occuranceNo]="Applying"
          }else{
            occurencesData[occuranceNo] = isGlossaryApplying.status
            ? "Pending"
            : currentGlossaryData?.status === "Applied"
            ? "Applied"
            : "NotApplied";
          }
          
        }
        applicationStatus[chapterNo] = occurencesData;
      }
    );

    if (Object?.keys(currentGlossaryData)?.length) {
      if (
        currentGlossaryData?.status === "Applying"
        // && isGlossaryApplying.status
      ) {
      
        let currentOccuranceNo = 0;
        Object.keys(currentGlossaryData?.affectedChaptersData || {})?.forEach(
          (occuranceNo) => {
            let changedParagraphData =
              currentGlossaryData?.affectedChaptersData[occuranceNo];
            if (!changedParagraphData.status) return;
            // affectedChaptersData.forEach((changedParagraphData)=>{
              console.log("check occurance ",occurrencesListData,applicationStatus?.[changedParagraphData?.chapter_number]?.[
                occuranceNo
              ],applicationStatus)
            if (
              applicationStatus?.[changedParagraphData?.chapter_number]?.[
                occuranceNo
              ]
            ) {
              applicationStatus[changedParagraphData?.chapter_number][
                occuranceNo
              ] = 
              getOccuranceStatus(
                changedParagraphData?.status,
                changedParagraphData?.ai_status
              );

              if(applicationStatus[changedParagraphData?.chapter_number][
                occuranceNo
              ]==="Applied" || applicationStatus[changedParagraphData?.chapter_number][
                occuranceNo
              ]==="Skipped" || applicationStatus[changedParagraphData?.chapter_number][
                occuranceNo
              ]==="AiSuggested"){
                currentOccuranceNo=occuranceNo
              }
            }
            setOccurrencesListData((prev) => {
              // Create a shallow copy of the prev state to avoid mutation
              let updatedOccurancesDetails = { ...prev }; // Shallow copy
            
              console.log("occurrenceList", prev);
              
              let updateCurrentChapter = updatedOccurancesDetails?.[changedParagraphData?.chapter_number]?.map(
                (chapterOccurance, i) => {
                  console.log("chapterOccurance", chapterOccurance);
            
                  // Only update the specific occurance
                  if (Number(occuranceNo) === chapterOccurance?.occuranceNo) {
                    chapterOccurance = { ...chapterOccurance }; // Create a new copy of chapterOccurance
            
                    // Update the latestTranslatedText
                    chapterOccurance.latestTranslatedText = '';
                    changedParagraphData?.after_sentence?.forEach((glossaryAppliedSentence) => {
                      chapterOccurance.latestTranslatedText += `${glossaryAppliedSentence} <br/>`;
                    });
                  }
            
                  return chapterOccurance; // Return the updated occurance
                }
              ) || [];
            
              // Return a new object with updated chapter data
              return {
                ...prev, // Copy the previous state
                [changedParagraphData?.chapter_number]: updateCurrentChapter, // Update only the chapter
              };
            });
  
                // if(!updatedOccurancesDetails?.[chapterNo]) return;
                // const parser = new DOMParser();
                // const glossaryAffectedSentenceElement=parser.parseFromString(glossaryAppliedSentence, "text/html");
                // let spanElements = glossaryAffectedSentenceElement.querySelectorAll('span[occurrence_number]');

                // if(spanElements?.length){
                //   let occurrenceNumbers =[]
                //   spanElements?.forEach((spanElement)=>occurrenceNumbers.push(Number(spanElement?.getAttribute('occurrence_number'))));
                //   console.log("spanElements",chapterNo,occurrenceNumbers,updatedOccurancesDetails)
                //   updatedOccurancesDetails[chapterNo]=updatedOccurancesDetails?.[chapterNo]?.map((chapterOccurance,i)=>{
                //      if(occurrenceNumbers.includes(chapterOccurance?.occuranceNo)){
                //       chapterOccurance.latestTranslatedText=glossaryAppliedSentence
                //      }
                //      return chapterOccurance
                //   })
                // }
             
            // changedParagraphData?.paragraph_occurrences_array?.forEach(
            //   (occuranceNo) => {
            //     if(!applicationStatus?.[chapterNo]?.[occuranceNo]) return;
            //     if(!changedParagraphData.after_sentences.length){
            //       applicationStatus[chapterNo][occuranceNo] = "Skipped";
            //     }else{
            //       applicationStatus[chapterNo][occuranceNo] = "Applied";
            //     }
            //     console.log("occuranceNo",occuranceNo,chapterNo)
            //     currentOccuranceNo=occuranceNo
            //   }
            // );
            // })
          }
        );
        let chapterCount = getChapterApplicationStatus(applicationStatus)
        console.log("debug progress",currentOccuranceNo,chapterCount,applicationStatus)
          setApplicationProgress({
            occuranceNo: currentOccuranceNo,
            chapterNo: chapterCount,
          });
      }
      
    }else{
      setApplicationProgress({
        occuranceNo: 0,
        chapterNo: 0,
      })
    }

    console.log(
      "currentGlossaryData",
      currentGlossaryData,
      applicationStatus,
      isGlossaryApplying
    );
    setGlossaryApplicationStatus(applicationStatus);
    
  }, [glossaryAppliedData, isGlossaryApplying]);



  useEffect(() => {
     if(!isGlossaryApplying.status && showLoader){
      setTimeout(()=>{
        setShowLoader(false)
      },2000)
     }
  }, [isGlossaryApplying.status])
  
 

  const getOccuranceNo = (chapterNo, occuranceNo) => {
    let finalOccuranceNo = 0;
    const keys =
      Object.keys(findOverBookData?.wordOccurrencesOverBook || {})?.sort(
        (a, b) => a - b
      ) || [];
    for (let i = 0; i < keys.length; i++) {
      const chapter = keys[i];
      if (chapter < chapterNo) {
        finalOccuranceNo += findOverBookData.wordOccurrencesOverBook[chapter];
      }
    }

    return finalOccuranceNo + occuranceNo;
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      // Add the panel to the expanded state if not already present
      setExpanded((prev) => [...prev, panel]);
    } else {
      // Remove the panel from the expanded state
      setExpanded((prev) => prev.filter((item) => item !== panel));
    }
  };

  const isOnCurrentOccurrence=(occurrenceNo,chapterNo)=>{
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    const currentChapterOccurenceNo=findAndReplaceEditing.state.highlightedOffset;
      if(currentChapter===chapterNo && occurrenceNo===currentChapterOccurenceNo)
        return true

    return false
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <LeftChevron
            className="cursor-pointer"
            onClick={() => {
              if(replaceTranslationLoader) return
              setSectionType("glossaryMatches");
              setDisableApplyButton(false);
              setShowLoader(false);
            }}
          />{" "}
          {(isGlossaryApplying.status && isGlossaryApplying.operationType==="fixAll") ? (
            <span className="text-base text-[#1E1C80]">
              Applying {findGlossaryData?.totalOccurances>1?"fixes":"fix"} for “
              {findOverBookData?.englishWord || highlightedEnglishText}”{" "}
            </span>
          ) : (glossaryAppliedData?.[
              findOverBookData?.englishWord || highlightedEnglishText
            ] &&  isGlossaryApplying.operationType==="fixAll") ? (
            <span className="text-base text-[#1E1C80]">
              Applied {findGlossaryData?.totalOccurances>1?"fixes":"fix"} for “
              {findOverBookData?.englishWord || highlightedEnglishText}”{" "}
            </span>
          ) : (
            <span className="text-base text-[#1E1C80]">
              ({findGlossaryData.totalOccurances}) Recommended {findGlossaryData?.totalOccurances>1?"Fixes":"Fix"} for "{" "}
              {findOverBookData?.englishWord || highlightedEnglishText}"
            </span>
          )}
        </div>
        <div>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => {
              if(isGlossaryApplying.status || replaceTranslationLoader) return 
               handleCloseGlossaryPannel();
              setUpdateIterationData({ chapterNo: "", iterationNo: "" });
              setDisableApplyButton(false);
              setShowLoader(false);
            }}
          />
        </div>
      </div>
      {/* {(isGlossaryApplying.status ||
        glossaryAppliedData?.[
          findOverBookData?.englishWord || highlightedEnglishText
        ]) && (
        <div className="flex gap-3.5 flex-col">
          <div className="flex items-center px-10 font-[Poppins] text-[12px] gap-2">
            <span>
              {applicationProgress.occuranceNo} of{" "}
              {findGlossaryData.totalOccurances} {findGlossaryData?.totalOccurances>1?"fixes":"fix"}
            </span>{" "}
            |
            <span>
              {applicationProgress.chapterNo} of{" "}
              {
                Object.keys(findOverBookData?.wordOccurrencesOverBook || {})
                  .length
              }
                {Object.keys(findOverBookData?.wordOccurrencesOverBook || {})
                  .length>1?" chapters":" chapter"}

            </span>{" "}
            |
            <span>
              {isGlossaryApplying.status ? "In progress" : "Completed"}
            </span>
          </div>
          {showLoader && <BorderLinearProgress
            variant="determinate"
            value={
              (100 / findGlossaryData?.totalOccurances) *
              applicationProgress.occuranceNo
            }
          />}
        </div>
      )} */}
      <div
        style={{
          height:
            isGlossaryApplying.status ||
            glossaryAppliedData?.[
              findOverBookData?.englishWord || highlightedEnglishText
            ]
              ? "calc(100dvh - 133px - 180px)"
              : "calc(100dvh - 133px - 172px)",
        }}
        className="flex flex-col occurrencesContainer px-2 hideSourceScrollBar overflow-y-scroll text-sm "
      >
        {findOverBookData?.wordOccurrencesOverBook &&
          Object?.keys(findOverBookData?.wordOccurrencesOverBook).map(
            (chapterKey) => {
              return (
                // <div className="flex flex-col  gap-2">
                //   <h1>Chapter: {chapterKey}</h1>
                //   <div className="flex flex-col px-4 gap-2">
                //     { Number(chapterKey)===occurrencesListData?.[0]?.chapterNo ?
                //     occurrencesListData?.[0]?.iterationList?.map((iteration)=>{
                //         return <div className="text-black text-sm">{iteration}</div>
                //     }): Array.from(
                //       { length: findOverBookData[chapterKey] },
                //       (_, i) => (
                //         <div
                //           onClick={() => {
                //             handleRedirection(i + 1, Number(chapterKey));
                //           }}
                //           className="cursor-pointer"
                //           key={i}
                //         >
                //           Occurance: {i + 1}/{findGlossaryData?.totalOccurances}
                //         </div>
                //       )
                //     )}
                //   </div>
                // </div>
                <Accordion
                  key={chapterKey}
                  expanded={expanded.includes(chapterKey)}
                  onChange={handleChange(chapterKey)}
                  sx={{
                    borderBottomLeftRadius: "0",
                    "&:last-of-type": {
                      borderRadius: "0px",
                    },
                    "&::before": {
                      height: 0, // Removes the height
                    },
                    boxShadow: "none",
                    // borderBottom: "1px solid #BFBFBF",
                    "& .MuiAccordion-region": { height: "100%" },
                    "& .MuiAccordionSummary-root": {
                      // Targeting the accordion summary
                      "&.Mui-expanded": {
                        // Adjust min-height when expanded
                        minHeight: "auto",
                      },
                    },

                    "& .MuiPaper-root": {
                      // Targeting the accordion summary
                      "&.Mui-expanded": {
                        // Adjust min-height when expanded
                        margin: "0px",
                      },
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<GlossaryViewIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      display: "flex",
                      borderBottomLeftRadius: "0",
                      paddingBottom: "0",
                      minHeight: "30px",
                      height: "45px",
                      paddingLeft: "5px",
                    }}
                  >
                    <div className="flex items-center gap-2 w-full">
                      {/* {getChapterApplicationStatus(chapterKey) ===
                        "Pending" && (
                        <div
                          style={{ border: "5.333px solid #FFFAEB" }}
                          className="w-8 h-8 p-2 flex items-center justify-center bg-[#FEF0C7] rounded-[18.667px]"
                        >
                          <PendingOutline className="shrink-0" />
                        </div>
                      )}
                      {getChapterApplicationStatus(chapterKey) ===
                        "Applied" && (
                        <div
                          style={{ border: "5.333px solid #ECFDF3" }}
                          className="w-8 h-8 p-2 flex items-center justify-center bg-[#D1FADF] rounded-[18.667px]"
                        >
                          <CheckCircle className="shrink-0" />
                        </div>
                      )} */}
                      <div className="flex justify-between  w-full items-center ">
                        <span className="text-sm font-[500] w-[50%] text-[#1E1C80]">
                          CHAPTER {chapterKey}
                        </span>

                        <span className="text-[12px] pr-2 text-[#000000]">
                          {
                            findOverBookData?.wordOccurrencesOverBook?.[
                              chapterKey
                            ]
                          }{" "}
                          {
                            findOverBookData?.wordOccurrencesOverBook?.[
                              chapterKey
                            ]===1 ?"fix" :"fixes"
                          }
                        </span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0",
                      border: "none",
                      boxShadow: "none",
                      borderBottomLeftRadius: "0",
                      height: "100%",
                    }}
                  >
                    <div className="flex items-center px-2 h-full gap-2">
                      {/* {(getChapterApplicationStatus(chapterKey) ===
                        "Applied" ||
                        getChapterApplicationStatus(chapterKey) ===
                          "Pending") && (
                        <div
                          style={{ borderLeft: "1px dashed #E3E3E3" }}
                          className="h-full self-stretch w-4 ml-[14px]"
                        ></div>
                      )} */}
                      <div className="flex flex-col w-full  pt-2  gap-4">
                        {
                          occurrencesListData[chapterKey]?.length > 0 &&
                            occurrencesListData[chapterKey]?.map(
                              (iteration, i) => {
                                return glossaryApplicationStatus[chapterKey][
                                  iteration.occuranceNo
                                ] === "Applied" ||
                                  glossaryApplicationStatus[chapterKey][
                                    iteration.occuranceNo
                                  ] === "Skipped" ||
                                  glossaryApplicationStatus[chapterKey][
                                    iteration.occuranceNo
                                  ] === "AiSuggested" ? (
                                  <div
                                    key={i}
                                    style={{ border: isOnCurrentOccurrence(i + 1,Number(chapterKey))  && "1px solid #3B37FF"}}
                                    className={cn(" bg-[#F5F7FF] rounded  px-3 pt-3 pb-2 flex gap-3 flex-col ",isOnCurrentOccurrence(i + 1,Number(chapterKey))&&"selectedOccurrence")}
                                  >
                                    <div
                                      onClick={() =>
                                        handleRedirection(
                                          i + 1,
                                          Number(chapterKey)
                                        )
                                      }
                                      className="flex flex-col cursor-pointer  gap-2"
                                    >
                                      <div className="flex justify-between items-center">
                                        <span className="text-[#475467] text-[12px]">
                                          Fix{" "}
                                          {getOccuranceNo(Number(chapterKey), i + 1)}/
                                          {findGlossaryData?.totalOccurances}
                                        </span>
                                        {glossaryApplicationStatus[chapterKey][
                                          iteration.occuranceNo
                                        ] === "Skipped" && (
                                          <div
                                            style={{
                                              border: "1px solid #E59A96",
                                            }}
                                            className="rounded-[12px] px-3 py-[2px] flex items-center text-[12px] font-[500] gap-2 bg-[#FFF0F0]  text-[#D25E58]"
                                          >
                                            <CircleExclamation />
                                            <span>No change required</span>
                                          </div>
                                        )}

                                        {glossaryApplicationStatus[chapterKey][
                                          iteration.occuranceNo
                                        ] === "AiSuggested" && (
                                          <div
                                            style={{
                                              border: "1px solid #D19200",
                                            }}
                                            className="rounded-[12px] px-3 py-[2px] flex items-center text-[12px] font-[500] gap-2 bg-[#FFF8E7]  text-[#D19200]"
                                          >
                                            <Stars className="text-[#D19200]" />
                                            <span>New translation</span>
                                          </div>
                                        )}    
                                      </div>

                                      <div
                                        className=""
                                        dangerouslySetInnerHTML={{
                                          __html: iteration?.englishText,
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        boxShadow:
                                          "0px 2px 10px 0px rgba(0, 0, 0, 0.04), 2px 4px 4px 0px rgba(0, 0, 0, 0.00)",
                                        borderRadius: "4px 4px 0px 0px",
                                      }}
                                      className="flex gap-2 flex-col rounded-xl pb-3 pt-4 px-3 bg-white"
                                    >
                                      <GlossaryAffectedSentence iteration={iteration} openedGlossaryData={openedGlossaryData}/>
                                      <div className="flex justify-end">
                                        {occurrenceNoToEdit !==
                                          iteration.occuranceNo && (
                                          <div
                                            onClick={() =>
                                              setOccurrenceNoToEdit(
                                                iteration.occuranceNo
                                              )
                                            }
                                            className="flex items-center gap-2 cursor-pointer"
                                          >
                                            {" "}
                                            <EditPencil className="" />{" "}
                                            <span className="font-[500] text-[12px] text-[#3B37FF]">
                                              Edit Variant
                                            </span>
                                          </div>
                                        )}
                                        {occurrenceNoToEdit ===
                                          iteration.occuranceNo && (
                                          <EditTranslation
                                            replaceTranslationLoader={
                                              replaceTranslationLoader
                                            }
                                            setReplaceTranslationLoader={
                                              setReplaceTranslationLoader
                                            }
                                            englishSentence={
                                              iteration?.englishText
                                            }
                                            occurrenceNoToEdit={
                                              occurrenceNoToEdit
                                            }
                                            changedTranslatedSentence={
                                              iteration?.latestTranslatedText
                                            }
                                            englishWord={findOverBookData?.englishWord || highlightedEnglishText}
                                            setOccurrenceNoToEdit={setOccurrenceNoToEdit}
                                            translateEditor={translateEditor}
                                            findOverBookData={findOverBookData}
                                            chapterKey={chapterKey}
                                            chapter={chapter}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      handleRedirection(
                                        i + 1,
                                        Number(chapterKey)
                                      );
                                    }}
                                    key={i}
                                    style={{ border: isOnCurrentOccurrence(i + 1,Number(chapterKey)) ?"1px solid #3B37FF":"1px solid #DFDFDF" }}
                                    className={cn("text-black flex gap-2 flex-col cursor-pointer p-3 rounded text-sm",isOnCurrentOccurrence(i + 1,Number(chapterKey))&&"selectedOccurrence")}
                                  >
                                    <div className="flex justify-between items-center">
                                      <span className="text-[#475467] text-[12px]">
                                        Occurance:{" "}
                                        {getOccuranceNo(Number(chapterKey), i + 1)}/
                                        {findGlossaryData?.totalOccurances}
                                      </span>
                                      {glossaryApplicationStatus[chapterKey][
                                        iteration.occuranceNo
                                      ] === "Applying" && (
                                        <CircularProgress
                                          sx={{ color: "gray" }}
                                          size={16}
                                        />
                                      )}
                                    </div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: iteration.englishText,
                                      }}
                                    />
                                  </div>
                                );
                              }
                            )
                          // : Array?.from(
                          //     {
                          //       length:
                          //         findOverBookData?.wordOccurrencesOverBook?.[
                          //           chapterKey
                          //         ],
                          //     },
                          //     (_, i) => {
                          //       return (
                          //         <div
                          //           onClick={() => {
                          //             handleRedirection(
                          //               i + 1,
                          //               Number(chapterKey)
                          //             );
                          //           }}
                          //           key={i}
                          //           style={{ border: "1px solid #DFDFDF" }}
                          //           className="text-black p-3 cursor-pointer rounded text-sm"
                          //         >
                          //           Occurance: {i + 1}/
                          //           {findGlossaryData?.totalOccurances}
                          //         </div>
                          //       );
                          //     }
                          //   )
                        }
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
      </div>
    </div>
  );
}

export default GlossaryApplicationStatus;
