import React, { useEffect, useState } from "react";
import "./SourceEditor.scss";
import "../../Pages/ReviewTranslation/ReviewTranslationPage.scss"
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import WordCounter from "../WordCounter/WordCounter";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import { ReactComponent as Library } from "../../assets/library.svg";
import { customizeSourceFindDialog, removeButton, scrollToHighlightedText } from "../../utils/translationUtil";
import { setGlossaryScreenType } from "../../App/editor/editor.actions";
import { useDispatch } from "react-redux";

function SourceEditor({
  sourceContentData,
  editorConfig,
  setSourceEditorr,
  setIsSourceEditorReady,
  sourceWordCountData,
  translatedChapterInfo,
  currentChapter,
  handleFindAndReplaceWordOverBook,
  sourceEditor,
  setTextToAddInGlossary,
  textToAddInGlossary,
  glossarySectionToggle,
  individualScroll,
  setGlossarySectionToggle
}) {
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0, visible: false });
  const [highlightedText, setHighlightedText] = useState({text:"",occurrenceNo:0})
  const dispatch=useDispatch()
  const {
    data: chapter,
    isPending: fetchTranslatedChapterPending,
    isError: fetchTranslatedChapterError,
    isSuccess: fetchTranslatedChapterSuccess,
  } = translatedChapterInfo;
   
  const disableHighlightingAndButton =()=>{
    const activeElement = document.activeElement;
    if (activeElement.tagName !== 'INPUT' && activeElement.tagName !== 'TEXTAREA' && !activeElement.isContentEditable) {
      window.getSelection().removeAllRanges();
      setButtonPosition({ visible: false });
    }
  }


  const getHighlightedTextOccurrenceNo=(sourceEditor,selectedRange,highlightedText)=>{
    const editorElement = sourceEditor.ui?.view?.editable?.element;
    const sourceEditorContent=editorElement?.textContent;
    const regex = new RegExp(`\\b${highlightedText}\\b`, "gi");
    const matches = [...sourceEditorContent.matchAll(regex)];
    let selectedIndex = null
    const selectedStartOffset = selectedRange.startOffset;
    const selectedEndOffset = selectedRange.endOffset;
    matches.forEach((match, index) => {
      
      const matchStart = match.index;
      const matchEnd = matchStart + highlightedText.length;

      // Check if the selected text position matches this occurrence in the paragraph
      if (selectedStartOffset >= matchStart && selectedEndOffset <= matchEnd) {
        selectedIndex = index + 1; // Occurrence number (1-based)
      }
    });
    console.log("find state",selectedIndex,selectedRange,highlightedText,matches)
  }


  const handleNativeSelection = (e) => {
    setTimeout(()=>{
      const editorElement = sourceEditor.ui?.view?.editable?.element;
      const editorToolBarElement=document.querySelector('.ck .ck-toolbar__items')
      const selection = window.getSelection();
      const selectedText = selection?.toString()?.trim();
      
      const editorRange = document.createRange();
      editorRange.selectNodeContents(editorElement);
      console.log("selection",selection)  
      if(!editorElement.contains(e.toElement) && !glossarySectionToggle)
        disableHighlightingAndButton()

      if (selectedText && editorElement.contains(selection.anchorNode) && !glossarySectionToggle) {
        
        // Get the range of the selected text
        const range = selection.getRangeAt(0);
        const highlightedTextOccurrence=getHighlightedTextOccurrenceNo(sourceEditor,range,selectedText)
        setHighlightedText({text:selectedText,occurrenceNo:0})
        const rect = range?.getBoundingClientRect();
        const mainContainer = document.querySelector(individualScroll?"#sourceContainer":'#bookEditorContainer');
        if(!mainContainer) return;
        console.log("selectedText",mainContainer?.scrollTop,rect.top)
        // Set button position relative to selected text
        let rectLeft=rect.left + (rect.width / 2) - (80)
        let positonLeft=glossarySectionToggle?(rectLeft + mainContainer?.scrollLeft) - 700 :(rectLeft + mainContainer.scrollLeft)
        let positionTop=individualScroll?rect.top:rect.top + mainContainer?.scrollTop
        setButtonPosition({
          top: positionTop, // Adjust for scroll position
          left: positonLeft,
          visible: true,
        });
      } else {
        setButtonPosition({ visible: false });
      }
    },0)
   
  };


  useEffect(() => {
    const editorElement = sourceEditor.ui?.view?.editable?.element;
    if(editorElement){
      // Attach native selection listeners
      window.addEventListener('mouseup', handleNativeSelection);
      window.addEventListener('keyup', handleNativeSelection);
      if(individualScroll){
        let sourceElement=document.querySelector("#sourceContainer")
        sourceElement.addEventListener('scroll',disableHighlightingAndButton)
      }else{
        const bookContainer=document.querySelector("#bookEditorContainer")
        bookContainer.addEventListener('scroll',disableHighlightingAndButton)
      }
    }

    const handleClickOutside = (event) => {
      const addToGlossaryButton=document.querySelector(".addToGlossaryButton");

      if (!editorElement?.contains(event.target) && addToGlossaryButton!==event.target) {
        setButtonPosition({ visible: false }); // Hide button when clicking outside the editor
      }
    };

    document.addEventListener('mousedown', handleClickOutside);


    return () => {
      if (sourceEditor) {
        const editorElement = sourceEditor.ui?.view?.editable?.element;
        window.removeEventListener('mouseup', handleNativeSelection);
        window.removeEventListener('keyup', handleNativeSelection);
        setTextToAddInGlossary({})
        setButtonPosition({ top: 0, left: 0, visible: false })
        if(individualScroll){
          let sourceElement=document.querySelector("#sourceContainer")
          sourceElement?.removeEventListener('scroll',disableHighlightingAndButton)
        }else{
          const bookContainer=document.querySelector("#bookEditorContainer")
          bookContainer?.removeEventListener('scroll',disableHighlightingAndButton)
        }
      }
    };
  }, [sourceEditor,glossarySectionToggle,individualScroll])
  
  const handleSourceEditorReady = (editor) => {
    setSourceEditorr(editor)
    editor.enableReadOnlyMode(editor.id);
    const toolbarElement = editor.ui.view.toolbar.element;
    const buttons = toolbarElement.querySelectorAll('.ck-button');
    let findAndReplaceButton=null;
    buttons.forEach(button => {
      const label = button.querySelector('.ck-button__label');
      if (label && label.textContent.trim() === 'Find and replace') {
        findAndReplaceButton=button
      }
    });
  //   const findAndReplaceButton = toolbarElement.querySelector('[data-cke-tooltip-text="Find and replace (Ctrl+F)"]');
   if(findAndReplaceButton){
        // To change the tooltip
    findAndReplaceButton?.setAttribute('data-cke-tooltip-text', 'Find');
    removeButton(toolbarElement, '.ck .ck-toolbar__items');
    const sourceToolBarContainer = document.querySelector('.sourceFindButton');
    sourceToolBarContainer.append(findAndReplaceButton);


    // To change the icon
    const button = document.querySelector('.ck.ck-button[data-cke-tooltip-text="Find"]') ?? {style:""};
    button.style.cursor = 'pointer';
    const svgElem = button?.querySelector('svg');
    if (svgElem) {
      svgElem.outerHTML = `
        <svg class="${svgElem.getAttribute('class')}" viewBox="0 0 24 24"  width="24" height="24" fill="#1e1c80">
         <circle cx="8.5" cy="8.5" r="5.5" stroke="#1e1c80" stroke-width="2" fill="none"></circle>
  <line x1="12.5" y1="12.5" x2="18" y2="18" stroke="#1e1c80" stroke-width="2" stroke-linecap="round"></line>
        </svg>
    `;
    }
   }
    

    // To hide the replace input
    const findAndReplaceUiPlugin = editor.plugins.get("FindAndReplaceUI");
    const dialogPlugin = editor.plugins.get("Dialog");
    dialogPlugin.on("show", (evt, data) => {
      const findAndReplaceDialogue = document.querySelector(
        '[aria-label="Find and replace"]'
      );
      findAndReplaceDialogue.style.opacity="0";
      setTimeout(()=>{
        dialogPlugin.view.moveTo(700,200);
        findAndReplaceDialogue.style.opacity="1";

      },100)
      customizeSourceFindDialog();
      const { formView: findAndReplaceDialogFormView } = findAndReplaceUiPlugin;
      const checkBookOverCountEleQuery =
      ".ck.ck-find-and-replace-form .ck-find-and-replace-form__inputs .sourcecountBookOver";
      handleFindAndReplaceWordOverBook(findAndReplaceDialogFormView,checkBookOverCountEleQuery,"english")
  

      const { formView: sourceFindDialogFormView } = findAndReplaceUiPlugin;
      sourceFindDialogFormView.on("findNext", async (event, data) => {
        const scrollLocked = document.querySelector(
          '[data-cke-tooltip-text="Unlock scroll"]'
        );
        const individualScroll=scrollLocked?false:true
        scrollToHighlightedText("source",individualScroll);
      })
      sourceFindDialogFormView.on("change:_resultsCounterText", async (event, data) => {
        const scrollLocked = document.querySelector(
          '[data-cke-tooltip-text="Unlock scroll"]'
        );
        const individualScroll=scrollLocked?false:true
        scrollToHighlightedText("source",individualScroll);
      })
    })

    setIsSourceEditorReady(true);
  };
 
  const handleAddToGlossary=()=>{
    if(!highlightedText.text) return ;
    setTextToAddInGlossary(highlightedText);
    setButtonPosition({ top: 0, left: 0, visible: false })
    dispatch(setGlossaryScreenType("glossaryMatches"))
    setGlossarySectionToggle(true)
  }

  return (
    <div id="source" className="">
      <CKEditor
        editor={Editor}
        data={sourceContentData?.length > 0 ? sourceContentData : ""}
        config={editorConfig}
        onChange={(event, editor) => {
          const data = editor.getData();
        }}
        onReady={handleSourceEditorReady}
      />
        {buttonPosition.visible && (
        <div
          style={{
            position: 'absolute',
            top: buttonPosition.top - 175, // Adjust for button height
            left: buttonPosition.left,
            zIndex:99999,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            width:"150px"
          }}
        >
          <div  onClick={handleAddToGlossary}
          className="px-2 addToGlossaryButton relative rounded-sm flex bg-white text-[#3B37FF] cursor-pointer items-center text-sm gap-2  py-[6px]"

           >
          <Library /> View glossary
          </div>
          <div className="w-2 bg-white absolute left-[50%] shadow-lg -bottom-[3px] h-2" style={{transform:"rotate(45deg)",borderRadius: "0px 0px 2px 0px"}}></div>
        </div>
      )}
      <WordCounter
        bookWords={sourceWordCountData.bookWords}
        chapterWords={sourceWordCountData.chapterWords}
        currentChapterNo={currentChapter}
        fetchTranslatedChapterPending={fetchTranslatedChapterPending}
        editorType={"sourced"}
        fetchTranslatedChapterError={fetchTranslatedChapterError}
      />
    </div>
  );
}

export default SourceEditor;
