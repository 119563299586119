import React, { useEffect, useState } from "react";

import "./EditTranslation.scss";
import { useSelector } from "react-redux";
import { ReactComponent as UndoIcon } from "../../assets/undoIcon.svg";
import EditGlossaryModal from "../EditGlossaryModal/EditGlossaryModal";
import { useParams } from "react-router-dom";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import { applyGlossaryOnOccurrence } from "../../utils/glossaryUtils";
import { CircularProgress } from "@mui/material";
import {Tooltip, Typography, styled } from "@mui/material";
function EditTranslation({
  englishWord,
  //   selectedGlossary,
  //   setSelectedGlossary,
  changedTranslatedSentence,
  setShowEditTranslation,
  setOccurrenceNoToEdit,
  occurrenceNoToEdit,
  englishSentence,
  replaceTranslationLoader,
  setReplaceTranslationLoader,
  translateEditor,
  findOverBookData,
  chapterKey,
  chapter
}) {
  const params=useParams();
  const { glossaryList,currentChapter,glossaryAppliedData } = useSelector((state) => state.editor);
  const [variantList, setVariantList] = useState([]);
  const [variantInput, setVariantInput] = useState("");
  const [selectedGlossary, setSelectedGlossary] = useState({});
  const [appliedTranslation, setAppliedTranslation] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [glossaryDetails, setGlossaryDetails] = useState({})
  const { loggedInUserEmail} = useSelector((state) => state.main);

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "white",
      color: "black",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      padding:"0"
    //   width:"170px"
    },
    "& .MuiTooltip-arrow": {
      color: "white", // Arrow color
    },
}));


  const getSourceHtmlParagraph = (englishSentence) => {
    const parser = new DOMParser();
    const englishSentenceElem = parser.parseFromString(
      englishSentence,
      "text/html"
    );
    const paragraphs = findOverBookData?.chapterWiseOccurancePara?.[chapterKey]?.English || [];
    let matchingParagraph = null;

    // Loop through each paragraph to find the one containing the sentence
    paragraphs.forEach((paragraph) => {
      let paragraphElem=parser.parseFromString(paragraph,"text/html");
      if (paragraphElem?.body?.textContent?.includes(englishSentenceElem?.body?.textContent)) {
        matchingParagraph = paragraph;
      }
    });

    return matchingParagraph;
  };

  useEffect(() => {
    if (glossaryList) {
      const parser = new DOMParser();
      const changedTranslatedSentenceElem = parser.parseFromString(
        changedTranslatedSentence,
        "text/html"
      );

      const spanList =
        changedTranslatedSentenceElem.querySelectorAll("span.added,span.skipped");

      let glossaryItem = glossaryList.find(
        (glossary) => glossary.english_word === englishWord
      );


      if (!glossaryItem || !changedTranslatedSentenceElem) return;

      setGlossaryDetails(glossaryItem)
      let appliedVariantDetails;
      spanList.forEach((span) => {
        const attributeValue = span?.getAttribute("variant_used") || "none";
         appliedVariantDetails = glossaryItem.dutch_word_array.find(
          (variant) =>
            (variant.replacement_dutch_word.toLowerCase() ===
            span.textContent.toLowerCase()) || (attributeValue.trim().toLowerCase() === variant.replacement_dutch_word.toLowerCase())
        );
        
      });

      

      if (appliedVariantDetails?.replacement_dutch_word) {
        setAppliedTranslation(appliedVariantDetails?.replacement_dutch_word)
        if(!selectedGlossary?.variantIndex){
          setSelectedGlossary({
            englishWord: englishWord,
            variant: appliedVariantDetails?.replacement_dutch_word,
          });
        } 
      }

      setVariantList(glossaryItem.dutch_word_array);
    }
  }, [glossaryList]);

  const handleRadioButtonChange = (e) => {
    if(replaceTranslationLoader) return;
    setSelectedGlossary({ englishWord: englishWord, variant: e.target.value });
  };

  const handleReplaceGlossary = async (isAddedVariantDetails)=>{
    if(replaceTranslationLoader) return 
    setReplaceTranslationLoader(true)
    const fixAllButton=document.querySelector('.fixAll')
    fixAllButton.disabled = true
    translateEditor.enableReadOnlyMode(translateEditor.id)
    console.log(glossaryDetails,"glossaryDetails")
    let variantIndex =
      isAddedVariantDetails?.variantIndex && isAddedVariantDetails?.variantIndex !== -1
        ? isAddedVariantDetails?.variantIndex
        : glossaryDetails.dutch_word_array.findIndex(
            (variant) =>
              variant.replacement_dutch_word === selectedGlossary.variant
          );
    if(isAddedVariantDetails){
      setTimeout(()=>{
        setSelectedGlossary(isAddedVariantDetails)
      },100)
    }
    let englishParagraph=getSourceHtmlParagraph(englishSentence)
    if(!englishParagraph || variantIndex===-1 || !loggedInUserEmail || (!isAddedVariantDetails?.variant && !selectedGlossary.variant)){
      setReplaceTranslationLoader(false)
      fixAllButton.disabled = false
      translateEditor.disableReadOnlyMode(translateEditor.id)
      return;
    }

    const payload={
      unique_string:params.id,
      glossary_id:glossaryDetails.id,
      chapter_id:`chapter_${currentChapter}`,
      original_english_paragraph_with_html:englishParagraph,
      variant_index:variantIndex,
      applied_by:loggedInUserEmail,
      application_source:"glossary",
      variant_selection_type:"per_variant_type",
      word_occurrence_number:occurrenceNoToEdit
    }

    console.log("payload",payload)
    await applyGlossaryOnOccurrence(payload)
    setReplaceTranslationLoader(false)
    fixAllButton.disabled = false
    translateEditor.disableReadOnlyMode(translateEditor.id)
    // setShowModal(true)

  }

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex flex-col gap-2">
        {variantList.map((variant) => {
          return (
            <div className="flex items-center gap-1">
              <input
                value={variant?.replacement_dutch_word}
                checked={
                  selectedGlossary?.variant === variant?.replacement_dutch_word
                }
                onChange={handleRadioButtonChange}
                type="radio"
                className="cursor-pointer"
              />
              <CustomTooltip
                placement="right"
                title={
                  <React.Fragment>
                    <div className="flex flex-col gap-2 p-3">
                      <div className="flex flex-col -mt-1 gap-[2px]">
                        <span className="text-[12px] text-[#475467] font-[Poppins]">
                          When to apply:
                        </span>
                        <span className="text-[12px] text-[#242424] font-[Poppins] font-[500]">
                          {variant?.instructions?.whenToApply?.trim() || "None"}
                        </span>
                      </div>

                      <div className="flex flex-col gap-[2px]">
                        <span className="text-[12px] text-[#475467] font-[Poppins]">
                          When to not apply:
                        </span>
                        <span className="text-[12px] font-[Poppins] font-[500]">
                          {variant?.instructions?.exception?.trim() || "None"}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                }
                arrow
              >
                <span className="text-sm  cursor-pointer">
                  {variant.replacement_dutch_word}
                </span>
              </CustomTooltip>
            </div>
          );
        })}

        {/* <div className="flex items-center gap-2">
          <input
            value={variantInput}
            checked={
              selectedGlossary?.variant === variantInput
            }
            onChange={handleRadioButtonChange}
            type="radio"
            className="mt-[6px]"
          />
           <textarea
            style={{ border: "1px solid #D9D9D9",fontFamily:"Poppins"}}
            onChange={(e)=>{setVariantInput(e.target.value)}}
            placeholder="Add option"
            value={variantInput}
            className="w-full h-[54px] hideSourceScrollBar whenToApply resize-none  text-sm rounded-[4px]  px-3 py-[5px] outline-none "
          />
        </div> */}
        <div
          onClick={() => {
            if(replaceTranslationLoader) return ;
            setSelectedGlossary({englishWord:englishWord,variant:false})
           setShowModal(true)
        }}
          className="text-[12px] text-[#3B37FF] ml-1 cursor-pointer flex items-center justify-start gap-1"
        >
          <AddGlossaryIcon className="text-[#3B37FF]" />
          Add variant
        </div>
      </div>
      <div className="flex items-center justify-end gap-4">
        <span
          className="text-[#3B37FF] font-[500] cursor-pointer text-[12px]"
          onClick={() => {
            if (replaceTranslationLoader) return;
            setOccurrenceNoToEdit(0);
          }}
        >
          Cancel
        </span>
        <span
          onClick={()=>{
             if(replaceTranslationLoader) return ;
            setShowModal(true)
          }}
          className="flex items-center gap-1 text-[#3B37FF] font-[500] cursor-pointer text-[12px]"
        >
          {replaceTranslationLoader ? (
            <CircularProgress
              className="mr-1"
              size={12}
              sx={{ color: "#3B37FF" }}
            />
          ) : (
            <UndoIcon />
          )}
          {replaceTranslationLoader ? "Replacing" : "Replace"}
        </span>
      </div>
      <EditGlossaryModal
        chapter={chapter}
        isAddVariant={!selectedGlossary?.variant}
        setOccurrenceNoToEdit={setOccurrenceNoToEdit}
        appliedTranslation={appliedTranslation}
        selectedVariant={selectedGlossary.variant}
        setShowModal={setShowModal}
        showModal={showModal}
        handleSaveChanges={() => {}}
        glossaryData={glossaryDetails}
        handleReplaceGlossary={handleReplaceGlossary}
      />
    </div>
  );
}

export default EditTranslation;
