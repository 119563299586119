import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";
import { ReactComponent as BackArrowIcon } from "../../assets/bigBackArrow.svg";
import CloseIcon from "@mui/icons-material/Close";


import { useSelector } from "react-redux";
import {Tooltip, styled } from "@mui/material";
import {
    addGlossarytoTheBook,
    updateGlossarytoTheBook,
  } from "../../utils/glossaryUtils";

function GlossaryFormComponent({
  chapter,
  setBookGlossaryData,
  englishWord,
  bookGlossaryData,
  operationType,
  setShowGlossaryForm,
  glossaryToEdit,
  setGlossaryToEdit,
  setSelectedEnglishWord,
  setGlossaryOperationType,
  handleCloseGlossary
  
}) {
  
  const { variant } = glossaryToEdit ?? {};
  const { instructions } = variant ?? {};
  
  const {
    whenToApply: whenToApplyEdit,
    exception: exceptionsEdit
  } = instructions ?? {}

  const { 
    replacement_dutch_word:replacement_dutch_word_edit,
    variant_scope:scope_edit
   } = variant ?? {};

  const returnEnglishWord = () => {
    if(operationType === 'edit'){
      return glossaryToEdit?.english_word
    }
    return englishWord ? englishWord :""
  }

  const [variantInputData, setVariantInputData] = useState({
    replacement_dutch_word: operationType === 'edit' ? replacement_dutch_word_edit:"",
    scope: operationType === 'edit' ? scope_edit :"",
    whenToApply: operationType === 'edit' ? whenToApplyEdit : "",
    exceptions: operationType === 'edit' ? exceptionsEdit : "",
    english_word: returnEnglishWord()
  });
  
  const [showFeilds, setShowFeilds] = useState({
    exception: false,
    whenToApplySuggestions: true,
  });

  const { loggedInUserEmail } = useSelector((state) => state.main);

  const bookInfo = {
    author: chapter.book_info.author_name,
    language: chapter?.translation_info?.translated_to,
    genre: chapter.book_info.genre,
  };

  const isGlossaryExist = useMemo(()=>{
    const object = bookGlossaryData?.find(
      (glossary) => glossary?.english_word?.toLowerCase() === variantInputData.english_word?.toLowerCase()
    )
    return object

  },[bookGlossaryData, variantInputData])

  const getNewVariant = (inputData, bookInfo) => {
    return {
      ...bookInfo,
      metadata_storage: {
        "creation date": new Date(),
        creation_user: loggedInUserEmail,
        application_history: [],
      },
      instructions: {
        whenToApply: inputData.whenToApply,
        exception: inputData.exceptions,
      },
      variant_scope: inputData.scope,
      replacement_dutch_word: inputData?.replacement_dutch_word?.trim(),
    };
  };

  const handleLocalStateUpdate = (id, mode, newWordObject) => {
    if(mode === "addForWord"){
      setBookGlossaryData(prevState => {
        return prevState.map(item => {
          if (item.id === id) {
            return {
              ...item,
              dutch_word_array: [...item.dutch_word_array, newWordObject]
            };
          }
          return item;
        });
      });
    }
    else if(mode === 'edit'){
      const updatedData = bookGlossaryData.map(item => ({
        ...item,
        dutch_word_array: item.dutch_word_array.map(dutchWordObj => {
          if (item?.id === id && dutchWordObj.replacement_dutch_word === replacement_dutch_word_edit) {
            return {
              ...dutchWordObj,
              instructions: {
                ...dutchWordObj.instructions,
                exception: newWordObject?.instructions.exception,
                whenToApply: newWordObject?.instructions.whenToApply
              },
              replacement_dutch_word: newWordObject.replacement_dutch_word,
              variant_scope: newWordObject.variant_scope
            };
          }
          return dutchWordObj;
        })
      }));
      setBookGlossaryData(updatedData);
    }
  };
  

  const returnUpdatedVariant = (mode) => {
    if(mode === 'add'){
      return [getNewVariant(variantInputData, bookInfo)]
    }
    else if(mode === 'addForWord'){
      return [...isGlossaryExist?.dutch_word_array,getNewVariant(variantInputData, bookInfo)]
    }
    else if(mode === 'edit'){
      const array = isGlossaryExist?.dutch_word_array.map((dutchWordObj)=>{
        if (dutchWordObj.replacement_dutch_word?.toLowerCase() === replacement_dutch_word_edit?.toLowerCase()) {
          return {
            ...dutchWordObj,
            instructions: {
              ...dutchWordObj.instructions,
              exception: variantInputData?.exceptions,
              whenToApply: variantInputData?.whenToApply
            },
            replacement_dutch_word: variantInputData?.replacement_dutch_word,
            variant_scope: variantInputData?.scope
          };
        }
        return dutchWordObj;
      })
      return array
    }
  }

  const returnGlossaryPayload = (mode) => {
    const dutchWordVariant = returnUpdatedVariant(mode);
    if(mode === 'add'){
        return {
          unique_string: chapter.translation_id,
          glossary_item: {
              ...bookInfo,
              english_word: variantInputData?.english_word,
              dutch_word_array: dutchWordVariant,
              glossary_item_scope: "Book",
              application_scope: "book_level",
              chapter_numbers: [],
          },
      }
    }
    else{
      return {
        ...bookInfo,
        english_word: variantInputData?.english_word,
        dutch_word_array: dutchWordVariant,
        glossary_item_scope: "Book",
        application_scope: "book_level",
        chapter_numbers: [],
      }
    }
  }
  
  const handleUpdateGlossary = async () => {
    try{
        if(!isGlossaryExist && operationType === 'add'){
          const glossaryPayload = returnGlossaryPayload('add')
          const response = await addGlossarytoTheBook(glossaryPayload);
          const { Glossary_item } = response ?? {};
          setBookGlossaryData((prev) => [Glossary_item,...prev])
          return
        }
        else if(isGlossaryExist && operationType === 'add'){
          const glossaryPayload = returnGlossaryPayload('addForWord');
          handleLocalStateUpdate(isGlossaryExist?.id,'addForWord',getNewVariant(variantInputData, bookInfo))
          await updateGlossarytoTheBook(glossaryPayload,isGlossaryExist?.id)
        }
        else if(operationType === 'edit'){
          const glossaryPayload = returnGlossaryPayload('edit');
          handleLocalStateUpdate(isGlossaryExist?.id,'edit',getNewVariant(variantInputData, bookInfo));
          await updateGlossarytoTheBook(glossaryPayload,isGlossaryExist?.id)
        }
    }
    catch(err){
        toast.error('Unable to add glossary')
    }
  }

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
      width:"170px"
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

  const { userOpenedBookInfo } = useSelector((state) => state.main);

  const handleVariantInputChange = (e) => {
    setVariantInputData({
      ...variantInputData,
      [e.target.name]: e?.target?.value,
    });

    // if(e.target.name==="replacement_dutch_word"){
    //   debounceGetVariantContextSuggestions(englishWord,e?.target?.value)
    // }
  };

  const isVariantAlreadyExists = (variant, variantList) => {
    if(operationType==="edit") return ;

    let isThisVariantAlreadyExists = variantList?.find(
      (dutchVariant) =>
        dutchVariant?.replacement_dutch_word?.toLowerCase() ===
        variant?.trim()?.toLocaleLowerCase()
    );

    if (isThisVariantAlreadyExists) {
      toast(`Variant already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return true;
    }

    return false;
  };

  const handleSaveGlossary = async () => {
    if (
      !variantInputData.replacement_dutch_word ||
      !variantInputData.scope ||
      !variantInputData.whenToApply ||
      !variantInputData.english_word
    )
      return;

    if (
      isGlossaryExist &&
      isVariantAlreadyExists(
        variantInputData.replacement_dutch_word.trim(),
        isGlossaryExist.dutch_word_array
      )
    ) {
      return;
    }
    await handleUpdateGlossary();
    setShowGlossaryForm(false);
    setVariantInputData({
      replacement_dutch_word: "",
      scope: "",
      whenToApply: "",
      exceptions: "",
      english_word:""
    });
  };

  return (
    <div className="flex pb-3 flex-col gap-4 h-full">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
                <BackArrowIcon style={{ cursor: "pointer" }} onClick={()=>{
                  setShowGlossaryForm(false);
                  setSelectedEnglishWord('');
                  setGlossaryOperationType('');
                  setGlossaryToEdit({});
                }}/>
                <span className="text-[#1E1C80] font-poppins text-base font-normal leading-normal">Add to glossary</span>
              </div>
              <CloseIcon
                className="cursor-pointer w-5 h-5"
                onClick={handleCloseGlossary}
              />
              </div>
    <div
      className="gap-5 flex flex-col  h-full rounded-[4px] bg-white"
    >
    <div className="flex flex-col gap-2">
        <span className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]">
          English word/phrase{" "}
          <span
            style={{ fontFamily: "SimSong" }}
            className="text-[#FF4D4F] text-sm"
          >
            *
          </span>
        </span>
        <input
          style={{ border: "1px solid #D9D9D9" }}
          placeholder="Type here"
          className="w-full text-sm rounded-[4px]  px-3 py-[5px] outline-none"
          name="english_word"
          value={englishWord? englishWord:variantInputData.english_word}
          onChange={handleVariantInputChange}
          autoComplete="off"
          disabled={englishWord || operationType === 'edit'}
        />
      </div>

      <div className="flex flex-col gap-2">
        <span className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]">
          Dutch Translation{" "}
          <span
            style={{ fontFamily: "SimSong" }}
            className="text-[#FF4D4F] text-sm"
          >
            *
          </span>
        </span>
        <input
          style={{ border: "1px solid #D9D9D9" }}
          placeholder="Type here"
          className="w-full text-sm rounded-[4px]  px-3 py-[5px] outline-none"
          name="replacement_dutch_word"
          value={variantInputData.replacement_dutch_word}
          onChange={handleVariantInputChange}
          autoComplete="off"
        />
      </div>
      <div className="w-full flex flex-col gap-2 relative">
        <label
          htmlFor="book-genre"
          className="text-[12px] text-[#000000D9] flex items-center gap-1 font-[poppins] font-[500]"
        >
          Apply this glossary to
          <span
            style={{ fontFamily: "SimSong" }}
            className="text-[#FF4D4F] text-sm"
          >
            *
          </span>
        </label>

        <div className=" w-full">
          <select
            id="book-sub-genre"
            className={`w-full  text-[#242424] py-[5px] px-3 outline-none text-sm`}
            placeholder="Enter the Sub-Genre of the book"
            style={{
              borderRadius: "4px",
              borderColor: "#D9D9D9",
              fontSize: "14px",
            }}
            onChange={handleVariantInputChange}
            name="scope"
            value={variantInputData.scope}
          >
            <option value="" disabled selected hidden>Select apply this glossary to</option>
            <option className="text-sm " value={"Book"} key={"book"}>
              Current book:{" "}
              {userOpenedBookInfo.title ?? chapter?.book_info?.book_title}
            </option>
            <option className="text-sm" value={"Author"} key={"author"}>
              Author:{" "}
              {userOpenedBookInfo?.author ?? chapter?.book_info?.author_name}
            </option>
            <option className="text-sm" value={"Genre"} key={"genre"}>
              Genre: {userOpenedBookInfo?.genre ?? "Romance"}
            </option>
            <option className="text-sm" value={"Language"} key={"language"}>
              Language: {userOpenedBookInfo?.translatedTo ?? "Dutch"}
            </option>
          </select>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <span className="text-[12px] flex items-center gap-1 text-[#000000D9] font-[poppins] font-[500]">
              When to apply
              <span
                style={{ fontFamily: "SimSong" }}
                className="text-[#FF4D4F] text-sm"
              >
                *
              </span>
            </span>
            <CustomTooltip
              sx={{
                "& .MuiTooltip-tooltip": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              placement="top-start"
              arrow
              title={
                <React.Fragment>
                <b>Example</b>
                : Light <br />
                Use light when referring to brightness, like switch on the light.
                </React.Fragment>
              }
            >
              <GlossaryInfoIcon className="cursor-pointer mb-[2px] text-[#999898] w-[14px] h-[14px]" />
            </CustomTooltip>
          </div>
          <textarea
            style={{ border: "1px solid #D9D9D9",fontFamily:"Poppins"}}
            onChange={handleVariantInputChange}
            name="whenToApply"
            placeholder="Type here"
            value={variantInputData.whenToApply}
            className="w-full h-[54px] hideSourceScrollBar whenToApply resize-none  text-sm rounded-[4px]  px-3 py-[5px] outline-none "
          />
        </div>
        {/* {showFeilds.whenToApplySuggestions && (
          <div className="flex flex-col gap-2">
            <div
              style={{ border: "1px solid #D9D9D9" }}
              onClick={() => {
                setVariantInputData({
                  ...variantInputData,
                  whenToApply:
                    "When someone inflates or amplifies a fact beyond truth.",
                });
                setShowFeilds({ ...showFeilds, whenToApplySuggestions: false });
              }}
              className="flex gap-2 py-1 px-2 cursor-pointer text-[10px] w-fit bg-[#F5F5F5] items-center"
            >
              <Stars />{" "}
              <span>
                When someone inflates or amplifies a fact beyond truth.
              </span>
            </div>
          </div>
        )} */}
        {/* {showFeilds.exception && (
          <input
            style={{ border: "1px solid #D9D9D9" }}
            onChange={handleVariantInputChange}
            name="exceptions"
            placeholder="Type the exceptions here"
            value={variantInputData.exceptions}
            autoComplete="off"
            className="w-full text-sm rounded-[4px]  px-3 py-[5px] outline-none "
          />
        )} */}

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <span className="text-[12px] flex items-center gap-1 text-[#000000D9] font-[poppins] font-[500]">
              When to not apply
              {/* <span
                style={{ fontFamily: "SimSong" }}
                className="text-[#FF4D4F] text-sm"
              >
                *
              </span> */}
            </span>
            <CustomTooltip
              sx={{
                "& .MuiTooltip-tooltip": {
                  backgroundColor: "black",
                  color: "white",
                  width:"170px"
                },
              }}
              placement="top-start"
              arrow
              title={
                <React.Fragment>
                <b>Example</b>
                : Light <br />
                Avoid using light for non-brightness contexts, like light weight.
                </React.Fragment>
              }
            >
            
              <GlossaryInfoIcon className="cursor-pointer mb-[2px] text-[#999898] w-[14px] h-[14px]" />
            </CustomTooltip>
            <span className="text-[#999898] text-[12px]">(optional)</span>
          </div>
          <textarea
            style={{ border: "1px solid #D9D9D9",fontFamily:"Poppins" }}
            onChange={handleVariantInputChange}
            name="exceptions"
            placeholder="Type here"
            value={variantInputData.exceptions}
            autoComplete="off"
            className="w-full h-[54px] whenToApply hideSourceScrollBar resize-none  text-sm rounded-[4px]  px-3 py-[5px] outline-none "
          />
        </div>
      </div>
      <div className="flex items-center pl-1 gap-[17px] text-xs">
        <span
          className="cursor-pointer text-[#979797]"
          onClick={() => {
            setVariantInputData({
              english_word: '',
              replacement_dutch_word: "",
              scope: "",
              whenToApply: "",
              exceptions: "",
            });
            setShowFeilds({ ...showFeilds, whenToApplySuggestions: true });
          }}
        >
          Reset
        </span>
        <span
          onClick={handleSaveGlossary}
          className="text-[#3B37FF] cursor-pointer"
        >
          Save
        </span>
      </div>
    </div>
    </div>
  );
}

export default GlossaryFormComponent;
