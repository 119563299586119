import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useSaveTranslatedChapter } from "../../hooks/useReviewTranslation";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import debounce from 'lodash.debounce';
import {
  applyPageLayout,
  getContentForEditor,
  enableSourceHighlightingOnHover,
  mouseoverSentence,
  mouseOutEventListner,
  replaceOverBook,
  getWordDifference,
  updateInstantLearningValue,
  getCurrentTime,
  checkIsUserActive,
  translateChapter,
  checkIsCurrentBookEngaged,
  updateUserActiveTime,
  searchTextOverBook,
  getNextChapterNumber,
  updateTotalMatchesOverBookElement,
  createTotalMatchesOverBookElement,
  resetTotalMatchesElement,
  scrollBarData,
  setEditorLayout,
  scrollToHighlightedText,
  replaceOverBookButtonQuery,
} from "../../utils/translationUtil";
import { cn } from "../../lib/utils";


import {
  handleAddNewParagraph,
  getItemFromCache,
} from "../../context/cache.service";
import { getFireStoreData, loggedInUserActivity, userLastActiveTime } from "../../utils/firebaseActions";
import DialogBox from "../DialogBox/DialogBox";
import { useDispatch, useSelector } from "react-redux";
import { CONCURRENT_EDITS_TRACKER, SCROLL_LOCK_BUTTON_CLICKS } from "../../utils/fireStoreTypes";
import store from "../../App/store";
import { ReactComponent as BackArrowIcon } from "../../assets/backArrow.svg";
import { ReactComponent as NextArrowIcon } from "../../assets/nextArrow.svg";
import { createRoot } from "react-dom/client";
import SourceEditor from "../SourceEditor/SourceEditor";
import TranslateEditor from "../TranslateEditor/TranslateEditor";


import { setFindAndReplaceWordMatchesCount, setGlossaryList, setGlossaryScreenType, setGlossarySuggestionsData, setIsGlossaryApplying } from "../../App/editor/editor.actions";

import Glossary from "../Glossary/Glossary";
import { applySelectedGlossary, updateGlossarytoTheBook ,getGlossarySuggestionData, getGlossaryItem, removeAddedSpanTags} from "../../utils/glossaryUtils";
import AlertDialog from "../AlertDialog/AlertDialog";
export let mostUsedTextIndent={offset:15.87,unit:"px"}
const DEFAULT_FONT_SIZE = "14px";
let mostUsedFontSize = DEFAULT_FONT_SIZE;
let fontSizeOptions = [
  "default",
  9,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
];

function BookEditor({
  isLayoutFixed,
  sourceContent,
  translatedContent,
  fixParagraphAlignment,
  setSourceContent,
  setTranslatedContent,
  setIsLayoutFixed,
  pageLayoutConfig,
  editorConfig: baseEditorConfig,
  translatedChapterInfo,
  setIndividualScroll,
  currentChapter,
  setIsTranslatedContentEdited,
  isTranslatedContentEdited,
  handleChapterChange,
  setAfterHandledUnsavedChangesAction,
  individualScroll,
  zoomLevel,
  onZoomChange,
  debouncedUpdateUserActiveTime,
  applyGlossary,
  fetchGlossarySuggestionsData,
  enableHighlightingState
}) {
  const params = useParams();
  const {
    data: chapter,
    isPending: fetchTranslatedChapterPending,
    isError: fetchTranslatedChapterError,
    isSuccess: fetchTranslatedChapterSuccess,
  } = translatedChapterInfo;

  const sourceContentData = useMemo(() => {
    return getContentForEditor(
      sourceContent,
      fetchTranslatedChapterSuccess,
      fetchTranslatedChapterPending,
      fetchTranslatedChapterError
    );
  }, [
    sourceContent,
    fetchTranslatedChapterSuccess,
    fetchTranslatedChapterPending,
    fetchTranslatedChapterError,
  ]);

  const translatedContentData = useMemo(() => {
    return getContentForEditor(
      translatedContent,
      fetchTranslatedChapterSuccess,
      fetchTranslatedChapterPending,
      fetchTranslatedChapterError
    );
  }, [
    translatedContent,
    fetchTranslatedChapterSuccess,
    fetchTranslatedChapterPending,
    fetchTranslatedChapterError,
  ]);

  const successHandler = (data) => null;

  const {
    data,
    mutate: saveEditedTranslatedChapter,
    error,
    isPending,
    isSuccess,
  } = useSaveTranslatedChapter({ successHandler });

  const [isSourceEditorReady, setIsSourceEditorReady] = useState(false);
  const [isTranslatedEditorReady, setIsTranslatedEditorReady] = useState(false);
  const [translateEditor, setTranslateEditor] = useState("");
  const [sourceEditor, setSourceEditorr] = useState("")
  const [glossarySectionToggle, setGlossarySectionToggle] = useState(false)
  const [translateWordCountData, setTranslateWordCountData] = useState({bookWords:0,chapterWords:0,chapterWordsDifference:0,bookWordsDifference:0});
  const [sourceWordCountData, setSourceWordCountData] = useState({bookWords:0,chapterWords:0});
  const [chaptersTranslationStatusList, setChaptersTranslationStatusList] = useState({})
  const [textToAddInGlossary, setTextToAddInGlossary] = useState({text:"",occurrenceNo:0})
  const { loggedInUserEmail} = useSelector((state) => state.main);
  const {glossaryList}=useSelector((state)=>state.editor)
  const [applyingGlossaryWarningPopup, setApplyingGlossaryWarningPopup] = useState(false)
  const [selectedGlossary, setSelectedGlossary] = React.useState({
    englishWord: "",
    variant: "",
  });
  const [appliedGlossaries, setAppliedGlossaries] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const checkUserActiveIntervalId = useRef(null);
  const unSubscribeRef = useRef(() => null);
  const findOverBookData = useRef({});

  const dispatch = useDispatch();
 
    
   
  const debounceSetEditorLayoutOnChange = useCallback(
    debounce( async (
        fetchTranslatedChapterPending,
        translatedContent,
        sourceContent,
        zoomLevel
      ) => {
        onZoomChange(zoomLevel);
        setEditorLayout(
          fetchTranslatedChapterPending,
          translatedContent,
          sourceContent
        );
      },
      0
    ),
    []
  );

  useEffect(() => {
    if(fetchTranslatedChapterPending) setIsLayoutFixed(false);
    if(translateEditor){
      translateEditor.model.document.on("change", (event, data) => {
       debounceSetEditorLayoutOnChange(
         fetchTranslatedChapterPending,
         translatedContent,
         sourceContent,
         zoomLevel
       );
      });
    }
    
  }, [translateEditor,zoomLevel,translatedContent,fetchTranslatedChapterPending])
  

  /**
   * Feature : Prevent concurrent user book access
   * checkBookEngagement function check if current Book occupied by any user 
   * and checks the current user is active or not if not it redirects back to dashboard
   */
  useEffect(() => {
    let isMounted = true;
    const checkBookEngagement = async () => {
      if (loggedInUserEmail) {
        const {isThisBookEngaged,currentBookOccupiedUser} = await checkIsCurrentBookEngaged(
          params.id,
          loggedInUserEmail
        );

        if (!isMounted) return;
        
        if (isThisBookEngaged) {
          toast(
            `Please wait until ${currentBookOccupiedUser} has finished working on the book`,
            {
              type: "warning",
              autoClose: 3000,
            }
          );
          setTimeout(() => {
            userLastActiveTime(params.id, "remove", loggedInUserEmail);
            navigate("/dashboard");
          }, 3000);
        } else {
          checkUserActiveIntervalId.current = setInterval(async () => {
            const lastActiveTime = await userLastActiveTime(
              params.id,
              "get",
              loggedInUserEmail
            );
            const currentTime = await getCurrentTime();
            const isCurrentUserActive = checkIsUserActive(
              currentTime,
              lastActiveTime
            );

            if (currentTime && !isCurrentUserActive || !lastActiveTime) {
              const isTranslatedContentEdited=store.getState().editor.isTranslationContentEdited
              if(isTranslatedContentEdited){
                const saveButton = document.querySelector(
                  '[data-cke-tooltip-text="Save chapter"]'
                );
                saveButton?.click();
                toast(
                  "Changes saved. Redirecting to the dashboard due to inactivity.",
                  { autoClose: 3000 }
                );
              }else{
                toast(
                  "Redirecting to the dashboard due to inactivity.",
                  { autoClose: 3000 }
                );
              }
              userLastActiveTime(params.id, "remove", loggedInUserEmail);
              setTimeout(() => {
                navigate("/dashboard");
                clearInterval(checkUserActiveIntervalId.current);
              }, 2000);
            }
          }, 60000);
        }
      }
    };

    checkBookEngagement();
    //Removing current user data from firestore 
    //clearing set interval 
    return () => {
      isMounted = false;
      userLastActiveTime(params.id, "remove", loggedInUserEmail);
      if (checkUserActiveIntervalId.current) {
        clearInterval(checkUserActiveIntervalId.current);
      }
    };
  }, [loggedInUserEmail]);

 

  /**
   * Feature : Chapter wise learning
   * handleNextChapterTranslation function  runs when user starts editing it checks if next  or
   * next to next chapter translated or not if not then triggers next chapter translation 
   * @param {*} sectionInformation is a object of chapters containing statuses 
   *  ("translated" | "translation_in_progress" | "translation_completed")
   * @param {*} chapterNo is current chapter number
   */
  const handleNextChapterTranslation= (sectionInformation,chapterNo)=>{
    let translatedChaptersList = sectionInformation ?? {};
    let nextChapterToTranslate = (chapterNo ?? 0) + 1;
    let isNextChapterTranslated =
      translatedChaptersList[nextChapterToTranslate]?.status ===
        "translated" ||
      translatedChaptersList[nextChapterToTranslate]?.status ===
        "translation_in_progress";
    if (isNextChapterTranslated) {
      nextChapterToTranslate++;
      isNextChapterTranslated =
        translatedChaptersList[nextChapterToTranslate]?.status ===
          "translated" ||
        translatedChaptersList[nextChapterToTranslate]?.status ===
          "translation_in_progress";
    }
    if (
      !isNextChapterTranslated &&
      translatedChaptersList[nextChapterToTranslate]
    ) {
      translateChapter(params.id, nextChapterToTranslate);
      translatedChaptersList[nextChapterToTranslate] = {
        status: "translation_in_progress",
      };
      setChaptersTranslationStatusList(translatedChaptersList);
    }
  }

 /**
  * 
  */
  const handleNextChapterTranslationDebounce=useCallback(
    debounce((sectionInformation,chapterNo) => handleNextChapterTranslation(sectionInformation,chapterNo), 1000),
    []
  );
  
  /**
   * updateLastActiveTimeOnScroll functions triggers updation of
   *  users last active time in the firestore when user starts scrolling
   * And also stores the scroll lock value and scroll position so that
   * it redirect to that when user again opens the book
   */ 
  const updateLastActiveTimeOnScroll =()=>{
    let currentChapterNo = store.getState().editor.currentChapter;
    const scrollLocked = document.querySelector(
      '[data-cke-tooltip-text="Unlock scroll"]'
    );
    const individualScroll=scrollLocked?false:true
    const scrollData={currentChapterNo,individualScroll}
    debouncedUpdateUserActiveTime(params.id,loggedInUserEmail,scrollData)
  }

  useEffect(() => {
   

    if (
      isSourceEditorReady &&
      isTranslatedEditorReady &&
      sourceContent && 
      translateEditor &&
      translatedContent
    ) {
     
      translateEditor.model.document.on("change", (event, data) => {
        if (data?.isUndoable && data?.operations?.length) {
          const glosaryApplyingStates =
            store.getState().editor.isGlossaryApplying.status;

          if (glosaryApplyingStates) {
            setApplyingGlossaryWarningPopup(true);
            return;
          }

          setIsTranslatedContentEdited(true);
          if (chapter?.sections_information) {
            handleNextChapterTranslationDebounce(chapter?.sections_information,chapter.chapter_number)
          }
          debouncedUpdateUserActiveTime(params.id,loggedInUserEmail);
        }
      });
       

       const instantLearningToggle= chapter?.instant_learning_toggle
       const instantLearningSwitch = document.querySelector(
         ".ck .ck-toolbar__items .ck-switchbutton"
       );
       
       if (instantLearningSwitch) {
         instantLearningSwitch.classList.remove("ck-disabled");

         const currentValue =
           instantLearningSwitch?.getAttribute("aria-pressed");
         if (String(instantLearningToggle) !== currentValue) {
           instantLearningSwitch.click();
         }
       }
       
      
        
      console.log("* Correct Paragraph Alignment *",isLayoutFixed);
      if (!isLayoutFixed) {
        if (typeof unSubscribeRef?.current === "function") {
          unSubscribeRef.current();
        }
        unSubscribeRef.current = enableSourceHighlightingOnHover(
          "#translated",
          chapter?.chapter_data?.English,
          handleAddNewParagraph,
          getItemFromCache,
          currentChapter,
          params.id
        );

        const { sourceContent: source, translatedContent: translated } =
          fixParagraphAlignment(sourceContent, translatedContent);
        setSourceContent(source);
        setTranslatedContent(translated);
        setIsLayoutFixed(true);
      }
    }

    return ()=> {
      debouncedUpdateUserActiveTime.cancel()
    }
  }, [
    isTranslatedEditorReady,
    isSourceEditorReady,
    sourceContent,
    translatedContent,
    enableHighlightingState
  ]);
  
  
 /**This function accepts sourceEditor ,translateEditor,chapterData and current chapter no
  * and updates source and translate editor word count
  * @param {*} sourceEditor 
  * @param {*} translateEditor 
  * @param {*} chapter  whole current chapter data
  * @param {*} currentChapterNo 
  */
  
  const updateEditorsWordCount = async (
    sourceEditor,
    translateEditor,
    translatedBookWordCountExeptCurrentChap,
    sourcedBookWordCountExeptCurrentChap,
    estimatedTranslateBookCount
  ) => {
     
    translatedBookWordCountExeptCurrentChap = translatedBookWordCountExeptCurrentChap || 0
    sourcedBookWordCountExeptCurrentChap = sourcedBookWordCountExeptCurrentChap || 0;
 
    const sourceWordCountPlugin = sourceEditor.plugins.get("WordCount");
    const translateWordCountPlugin = translateEditor.plugins.get("WordCount");
 

    let chapterWordsDifference = getWordDifference(
      translateWordCountPlugin.words,
      sourceWordCountPlugin.words
    );

    //if whole book is not translated then show estimated word count
    let updatedTranslatedBookCount = estimatedTranslateBookCount
      ? estimatedTranslateBookCount
      : translatedBookWordCountExeptCurrentChap +
        translateWordCountPlugin.words;
 
    let bookWordsDifference = getWordDifference(
      updatedTranslatedBookCount,
      sourcedBookWordCountExeptCurrentChap + sourceWordCountPlugin.words
    );
   

    /*Update Word count upon receiving Book words count from API */
    setTranslateWordCountData({
      bookWords:updatedTranslatedBookCount,
      chapterWords:translateWordCountPlugin.words,
      chapterWordsDifference:chapterWordsDifference,
      bookWordsDifference:bookWordsDifference,
      isAllChaptersNotTranslated:estimatedTranslateBookCount?true:false
    })

    setSourceWordCountData({
      bookWords:sourcedBookWordCountExeptCurrentChap+sourceWordCountPlugin.words,
      chapterWords:sourceWordCountPlugin.words
    })

   
    /*Update word count when user make changes in editor or chapter changes. */
 
    translateWordCountPlugin.on("update", (evt, stats) => {
      chapterWordsDifference = getWordDifference(stats.words,sourceWordCountPlugin.words);
      
      updatedTranslatedBookCount = estimatedTranslateBookCount
        ? estimatedTranslateBookCount
        : translatedBookWordCountExeptCurrentChap + stats.words;

      bookWordsDifference = getWordDifference(
        updatedTranslatedBookCount,
        sourcedBookWordCountExeptCurrentChap + sourceWordCountPlugin.words
      );
      
      setTranslateWordCountData({
        bookWords:updatedTranslatedBookCount,
        chapterWords:translateWordCountPlugin.words,
        chapterWordsDifference:chapterWordsDifference,
        bookWordsDifference:bookWordsDifference,
        isAllChaptersNotTranslated:estimatedTranslateBookCount?true:false
      })
    });
 
    sourceWordCountPlugin.on("update", (evt, stats) => {
      setSourceWordCountData({
        bookWords:sourcedBookWordCountExeptCurrentChap+sourceWordCountPlugin.words,
        chapterWords:sourceWordCountPlugin.words
      })
    });
  };

  

  const fetchGlossaryList= async(translationId,language)=>{
    if(glossaryList.length) return ;
    const glossaryItems = await getGlossaryItem(translationId,language);
      glossaryItems?.sort((a, b) => {
        if (a.english_word < b.english_word) return -1;
        if (a.english_word > b.english_word) return 1;
        return 0;
      });
     dispatch(setGlossaryList(glossaryItems ?? []))
  }

  

   useEffect(() => {
     if (sourceEditor && translateEditor && chapter) {
     
      //Trigger for storing last active time on parent container scroll
      const bookEditorContainer=document.querySelector("#bookEditorContainer")
       bookEditorContainer?.addEventListener('scroll',updateLastActiveTimeOnScroll);
      

     if(chapter.sections_information)
       setChaptersTranslationStatusList(chapter.sections_information)

      let {
        total_word_count_except_current_chapter_translated:translatedBookWordCountExeptCurrentChap,
        total_word_count_except_current_chapter_original:sourcedBookWordCountExeptCurrentChap,
        sections_information:chapterTranslationInfo,
        total_approximate_forcast_of_translated_book_max:estimatedTranslateBookCount
     } = chapter;

     let isAllChapterTranslated = true;
     for (const chapterKey in chapterTranslationInfo) {
       if (
         chapterTranslationInfo[chapterKey].status === "not_translated" ||
         chapterTranslationInfo[chapterKey].status === "translation_in_progress"
       )
         isAllChapterTranslated = false;
     }

    
     if(isAllChapterTranslated)
      estimatedTranslateBookCount=null;
      
      updateEditorsWordCount(
         sourceEditor,
         translateEditor,
         translatedBookWordCountExeptCurrentChap,
         sourcedBookWordCountExeptCurrentChap,
         estimatedTranslateBookCount
       );

       /**
        * Feature  : Bookmark
        * setBookMarkScrollData function gets scroll lock value and scroll positons
        * from firestore and sets elements scroll position
        */
       const setBookMarkScrollData=async()=>{
        if(!loggedInUserEmail) return ;
        const storeData=await getFireStoreData(CONCURRENT_EDITS_TRACKER)
        const loggedInUserData=storeData.bookMarkData?storeData.bookMarkData[loggedInUserEmail]:{}
        const currentBookBookMarkData=loggedInUserData?.[params.id] ?? {}
        if(!currentBookBookMarkData?.scrollBarDetails) return ;
         scrollBarData(
          "set",
          currentBookBookMarkData?.scrollBarDetails?.individualScroll,
          currentBookBookMarkData?.scrollBarDetails
        );
       }
       setBookMarkScrollData()

       fetchGlossarySuggestionsData(chapter.translation_id)
       fetchGlossaryList(chapter.translation_id,chapter?.translation_info?.translated_to?.toLowerCase() || "dutch")

      //  const sourceDialogPlugin = sourceEditor.plugins.get("Dialog");
      //  const translateDialogPlugin = translateEditor.plugins.get("Dialog");
      //  const findAndReplaceUiPlugin = translateEditor.plugins.get("FindAndReplaceUI");

      //  sourceDialogPlugin?.on("show", (evt, data) => {
      //   const translatedFindButton=
      //    console.log("findAndReplaceUiPlugin",translateDialogPlugin,findAndReplaceUiPlugin)
      //  })

      const scoredTranslatedParaList = document.querySelectorAll(
        "[confidence_score]"
      ) || [];
      scoredTranslatedParaList.forEach((translatedPara) => {
        let confidenceScore = translatedPara?.getAttribute("confidence_score")
        
        if (!confidenceScore) return;
        confidenceScore = Number(confidenceScore);
        console.log("confidenceScore",confidenceScore)
        if (confidenceScore >= 66) {
          translatedPara.classList.add("highConfidencePara");
        } else if (confidenceScore >= 50) {
          translatedPara.classList.add("moderateConfidencePara");
        } else {
          translatedPara.classList.add("lowConfidencePara");
        }

        if(!translatedPara.style.marginBottom){
          translatedPara.style.marginBottom='5px'
        }
      });
    
       return ()=>{
        const bookEditorContainer=document.querySelector("#bookEditorContainer")
        bookEditorContainer?.removeEventListener('scroll',updateLastActiveTimeOnScroll);
       }
     }
   }, [sourceEditor ,translateEditor, chapter]);

   
  /**
   * This function accepts style and translation content data and returns most used
   *  given style all over the translation data
   * @param {*} style  - Style is Dom style with semicolon  example : font-size:, text-align:
   * @param {*} translatedData
   * @returns
   */
  const getMostUsedStyle = (style, translatedData) => {
    const paraArray = translatedData?.split(style);
    paraArray?.shift();
    const mostUsedStyle = paraArray
      ?.map((x) => x.split(";")[0])
      .filter((x) => !["0px", "0", "0mm"].includes(x))
      .reduce(
        (a, b, i, arr) =>
          arr.filter((v) => v === a).length >= arr.filter((v) => v === b).length
            ? a
            : b,
        null
      );

    return mostUsedStyle;
  };



  useEffect(() => {
    // set default font size and text indent for the translated editor
    if (translatedContentData) {
      mostUsedFontSize =
        getMostUsedStyle("font-size:", translatedContentData) ||
        DEFAULT_FONT_SIZE;

      // Update font size options if most used font size is not there
      if (
        fontSizeOptions.findIndex(
          (value) => value === Math.round(parseFloat(mostUsedFontSize))
        ) === -1
      ) {
        fontSizeOptions.push(Math.round(parseFloat(mostUsedFontSize)));
        fontSizeOptions.sort((a, b) => {
          if (a === "default") return -1;
          if (b === "default") return 1;
          return a - b; // numeric comparison
        });
      }
    }
  }, [translatedContentData,translateEditor]);

  const editorConfig = {
    ...baseEditorConfig,
    licenseKey:
      "dDUwTTB2dXp4QngyQUxvSUJlWG9yS21kWkJwTDVuUUFmVUtuaHFnZ0pPYThXZUFBZlNpbXl6NzVtcHcrLU1qQXlOREEwTWpZPQ==",
    highlight: {
      options: [
        {
          model: "yellowMarker",
          class: "marker-yellow",
          title: "Yellow marker",
          color: "var(--ck-highlight-marker-yellow)",
          type: "marker",
        },
      ],
    },
    fontFamily: {
      options: [
        "default",
        "Arial, Helvetica, sans-serif",
        "Times New Roman, Times, serif",
      ],
    },
    fontSize: {
      options: fontSizeOptions,
      supportAllValues: true,
    },
    htmlSupport: {
      allow: [
        {
          name: "span",
          attributes: {
            "data-sentence-id": true,
          },
          styles: true,
          classes: true,
        },
        {
          name: "p",
          attributes: {
            "data-paragraph-id": true,
            "data-first-sentence-id": true,
            "data-connected-first-sentence-id": true,
            "data-spacer-paragraph-id": true,
            'data-confidence-score':true,
            "confidence_score":true
          },
          styles: true,
          classes: true,
        },
      ],
    },
    saveDocument: {
      saveDocument: (editor) => {
        const store = editor.config.get("store").getState();
        if (store.editor.fetchTranslatedChapterPending) return;
        let editedTranslation = editor.getData();
        
        const updatedChapter=removeAddedSpanTags(editor)
        if(updatedChapter)
          editedTranslation=updatedChapter

        editedTranslation = removeBySelector("p.spacer", editedTranslation);
        editedTranslation = removeAttributeBySelector(
          "data-first-sentence-id",
          "p[data-first-sentence-id]",
          editedTranslation
        );
        editedTranslation = removeAttributeBySelector(
          "data-connected-first-sentence-id",
          "p[data-connected-first-sentence-id]",
          editedTranslation
        );

        
        

        saveEditedTranslatedChapter({
          translation_id: params.id,
          chapter: store.editor.currentChapter,
          translated_data: editedTranslation,
        });
        toast("Chapter saved successfully", {
          type: "success",
          autoClose: 3000,
        });
        setIsTranslatedContentEdited(false);
      },
    },
    toggleGlossary:{
      toggleGlossary:(editor,isToggled)=>{
        if(isToggled){
          dispatch(setGlossaryScreenType("glossaryList"))
        }else{
          dispatch(setGlossaryScreenType(""))
        }
        setGlossarySectionToggle(isToggled)
      }
    },
    toggleScroll: {
      toggleScroll: async (editor, isToggled) => {
        setIndividualScroll(isToggled);
        const translatedElement = document.querySelector(
          "#translatedContainer"
        );
        const sourceElement = document.querySelector("#sourceContainer");
        const bookEditorContainer = document.querySelector(
          "#bookEditorContainer"
        );

        if (!isToggled) {
          const translatedTopScrollPosition = translatedElement?.scrollTop;
          setTimeout(() => {
            bookEditorContainer.scrollTo(0, translatedTopScrollPosition);
          }, 0);
          sourceElement?.removeEventListener(
            "scroll",
            updateLastActiveTimeOnScroll
          );
          translatedElement?.removeEventListener(
            "scroll",
            updateLastActiveTimeOnScroll
          );
        } else {
          const windowTopScrollPosition = bookEditorContainer.scrollTop;
          setTimeout(() => {
            if (windowTopScrollPosition) {
              sourceElement.scrollTop = windowTopScrollPosition;
              translatedElement.scrollTop = windowTopScrollPosition;
            }
          }, 0);
          sourceElement?.addEventListener(
            "scroll",
            updateLastActiveTimeOnScroll
          );
          translatedElement?.addEventListener(
            "scroll",
            updateLastActiveTimeOnScroll
          );
        }
        const currentChapterNo = editor.config.get("store").getState()
          .editor.currentChapter;
        const bookMarkData = {
          lastOpenedChapterNo: currentChapterNo,
          scrollBarDetails: scrollBarData("get", isToggled),
        };
        updateUserActiveTime(params.id, loggedInUserEmail, bookMarkData);
        loggedInUserActivity({ activity: SCROLL_LOCK_BUTTON_CLICKS });
      },
    },
    toggleInstantLearning: {
      toggleInstantLearning: async (editor, isToggled) => {
        updateInstantLearningValue(params.id, isToggled);
      },
    },
    wordCount: {
      displayCharacters: false,
    },
    findAndReplace: {
      uiType: "dialog",
    },
    indentBlock: mostUsedTextIndent,
  };

  /**
   * Remove extra attributes from the data.
   * @param {*} attribute Attribute to be removed
   * @param {*} selector selector from which attribute has to be removed
   * @param {*} data HTML as string
   * @returns
   */
  const removeAttributeBySelector = (attribute, selector, data) => {
    const domElement = document.createElement("div");
    domElement.innerHTML = data;
    const result = [...domElement.querySelectorAll(selector)];
    result.forEach((eleToRemoveAttribute) => {
      eleToRemoveAttribute.removeAttribute(attribute);
    });

    return domElement.innerHTML;
  };

  /**
   * Remove spacer element from the data.
   * @param {*} selector
   * @param {*} data
   * @returns
   */
  const removeBySelector = (selector, data) => {
    const domElement = document.createElement("div");
    domElement.innerHTML = data;
    const result = [...domElement.querySelectorAll(selector)];
    result.forEach((eleToRemove) => {
      eleToRemove.parentNode.removeChild(eleToRemove);
    });

    return domElement.innerHTML;
  };

  

  useEffect(() => {
    if (isSourceEditorReady && isTranslatedEditorReady) {
      applyPageLayout(pageLayoutConfig,"px");
    }
  }, [data, isLayoutFixed, isSourceEditorReady, isTranslatedEditorReady,pageLayoutConfig]);

   
  const toggleDialogBox =()=>{
    if(!dialogOpen){
      document.body.classList.add('no-scroll');
    }else{
      document.body.classList.remove('no-scroll');

    }
    setDialogOpen(!dialogOpen)
  }

   /**
   * Feature : Find and replace Over book
   * debouncedUpdateTotalMatchesOverBookElem is a debounce which 
   * triggers updation of Total matches over book element like
   * Total matches count and next and prev button activation deactivation 
   */ 
   const debouncedUpdateTotalMatchesOverBookElem = useCallback(
    debounce(
      async (formViewUi, handleNextButtonClick, handlePrevButtonClick,language) => {
        const { findAndReplaceWordMatchesCount } = store.getState().editor;
        const { _textToFind, _matchCase, _wholeWordsOnly, matchCount } =
          formViewUi;
        console.log(
          "running debounce",
          findAndReplaceWordMatchesCount,
          matchCount
        );
        if (findAndReplaceWordMatchesCount === "N/A") return;

        const data =
          (await searchTextOverBook(
            params.id,
            _textToFind,
            _matchCase,
            _wholeWordsOnly,
            language
          )) ?? {};
        findOverBookData.current = {
          ...findOverBookData.current,
          wordOccurrencesOverBook: data.word_occurrences,
        };

        const bookType=language==="dutch"?"translate":"source"

        updateTotalMatchesOverBookElement(
          data.word_occurrences,
          handleNextButtonClick,
          handlePrevButtonClick,
          bookType,
          matchCount
        );
      },
      0
    ),
    []
  );
  
  /**
   * Feature : Find and replace Over book
   * handleNextButtonClick handle next chapter redirection
   */
  const handleNextButtonClick = async () => {
    let reduxEditorStore = store.getState().editor;
    const { currentChapter, isTranslationContentEdited } = reduxEditorStore;
    let currentChapterNo = currentChapter
      ? Number(currentChapter)
      : currentChapter;
    const keys = Object.keys(
      findOverBookData.current.wordOccurrencesOverBook
    ).map(Number);
    keys.sort((a, b) => a - b);

    const isWordMatches = keys.length != 0 && keys.indexOf(currentChapterNo) == -1;
    if(isWordMatches){
      keys.push(currentChapterNo);
      keys.sort((a, b) => a - b);
    }
    const currentChapterIndex = keys.indexOf(currentChapterNo);

    if (currentChapterIndex === -1 || currentChapterIndex + 1 >= keys.length)
      return;

    const { nextChapterNo} = getNextChapterNumber(
      "next",
      findOverBookData.current.wordOccurrencesOverBook,
      currentChapterNo
    );

    if (nextChapterNo) {
      if (isTranslationContentEdited) {
        setAfterHandledUnsavedChangesAction({
          actionName: "nextClick",
          nextChapterNo: nextChapterNo,
        });
        return;
      }
      handleChapterChange(nextChapterNo);
    }
  };
 
   /**
   * Feature : Find and replace Over book
   * handleNextButtonClick handle prev chapter redirection
   */
  const handlePrevButtonClick = async () => {
    let reduxEditorStore = store.getState().editor;
    const { currentChapter, isTranslationContentEdited } = reduxEditorStore;
    let currentChapterNo = currentChapter
      ? Number(currentChapter)
      : currentChapter;

    const keys = Object.keys(
      findOverBookData.current.wordOccurrencesOverBook
    ).map(Number);
    keys.sort((a, b) => a - b);

    // Check if there are any keys (chapters) and if the current chapter is not in the list of keys.
    const isWordMatches = keys.length != 0 && keys.indexOf(currentChapterNo) == -1;
    if(isWordMatches){
      keys.push(currentChapterNo);
      keys.sort((a, b) => a - b);
    }
    const currentChapterIndex = keys.indexOf(currentChapterNo);
    if (currentChapterIndex === -1 || currentChapterIndex - 1 < 0) return;

    const { nextChapterNo} = getNextChapterNumber(
      "prev",
      findOverBookData.current.wordOccurrencesOverBook,
      currentChapterNo
    );

    if (nextChapterNo) {
      if (isTranslationContentEdited) {
        setAfterHandledUnsavedChangesAction({
          actionName: "previousClick",
          nextChapterNo: nextChapterNo,
        });
        return;
      }
      handleChapterChange(nextChapterNo);
    }
  };

  const handleFindAndReplaceWordOverBook=(findAndReplaceDialogFormView,checkBookOverCountEleQuery,language)=>{
    const bookType=language==="dutch"?"translate":"source"
    createTotalMatchesOverBookElement(
      createRoot,
      NextArrowIcon,
      BackArrowIcon,
      bookType
    );
    findOverBookData.current = {};

    findAndReplaceDialogFormView.on("findNext", async (event, data) => {
      console.log("running finNext");
      const {
        _textToFind,
        _matchCase,
        _wholeWordsOnly,
        highlightOffset,
        matchCount,
      } = findAndReplaceDialogFormView;
      const { textToFind, matchCase, wholeWordsOnly } =
        findOverBookData.current;
      if (
        textToFind !== _textToFind ||
        matchCase !== `${_matchCase}` ||
        wholeWordsOnly !== `${_wholeWordsOnly}`
      ) {
        const checkBookOverCountEle = document.querySelector(
          `${checkBookOverCountEleQuery} .totalMatchesCount`
        );
        checkBookOverCountEle.innerHTML = `Total matches over book : <span class="totalCount"> ... </span>`;
        const data =
          (await searchTextOverBook(
            params.id,
            _textToFind,
            _matchCase,
            _wholeWordsOnly,
            language
          )) ?? {};
        findOverBookData.current = {
          textToFind: _textToFind,
          matchCase: `${_matchCase}`,
          wholeWordsOnly: `${_wholeWordsOnly}`,
          wordOccurrencesOverBook: data.word_occurrences,
        };

        updateTotalMatchesOverBookElement(
          data.word_occurrences,
          handleNextButtonClick,
          handlePrevButtonClick,
          bookType,
          findAndReplaceDialogFormView?.matchCount
        );

        const isButtonAlreadyThere = document.querySelector(
          replaceOverBookButtonQuery
        );

        if (
          data?.word_occurrences &&
          Object.keys(data?.word_occurrences).length
        ) {
          dispatch(setFindAndReplaceWordMatchesCount(0));
          if(isButtonAlreadyThere && bookType==="translate"){
            isButtonAlreadyThere.style.opacity = "1";
            isButtonAlreadyThere?.classList?.add("enableHover");
          }
        } else {
          if(isButtonAlreadyThere){
            isButtonAlreadyThere.style.opacity = "0.5";
            isButtonAlreadyThere?.classList?.remove("enableHover");
          }
        }

      }
      const scrollLocked = document.querySelector(
        '[data-cke-tooltip-text="Unlock scroll"]'
      );
      const individualScroll=scrollLocked?false:true
      scrollToHighlightedText("translate",individualScroll);
    });

    // Resetting the matchCount whenever there is any change in the advanced options
    findAndReplaceDialogFormView.on("change", async (event, data) => {
      const isButtonAlreadyThere = document.querySelector(
        replaceOverBookButtonQuery
      );
      resetTotalMatchesElement(
        bookType,
        handleNextButtonClick,
        handlePrevButtonClick,
      );
      if(!isButtonAlreadyThere) return;
      isButtonAlreadyThere.style.opacity = "0.5";
      isButtonAlreadyThere?.classList?.remove("enableHover");
    });

    //enabling and disabling replaceOverBookButton if user input word is exist in the content
    findAndReplaceDialogFormView?.on(
      "change:_resultsCounterText",
      async (evt, data) => {
        const replaceOverBookButton =
          document.querySelector(replaceOverBookButtonQuery) || {};
        const buttonStyle = replaceOverBookButton?.style || {};
        const { _textToFind, _matchCase, _wholeWordsOnly, matchCount } =
          findAndReplaceDialogFormView;

        const {
          textToFind,
          matchCase,
          wholeWordsOnly,
          wordOccurrencesOverBook,
        } = findOverBookData?.current;
        console.log("running matches event", matchCount);
        if (matchCount) {
          buttonStyle.opacity = "1";
          replaceOverBookButton?.classList?.add("enableHover");
          if (
            _textToFind === textToFind &&
            wordOccurrencesOverBook &&
            `${_matchCase}` === matchCase &&
            `${_wholeWordsOnly}` === wholeWordsOnly
          ) {
            debouncedUpdateTotalMatchesOverBookElem(
              findAndReplaceDialogFormView,
              handleNextButtonClick,
              handlePrevButtonClick,
              language
            );
          }
        } else {
          findAndReplaceDialogFormView._replaceInputView.errorText = null;
          resetTotalMatchesElement(
            bookType,
            handleNextButtonClick,
            handlePrevButtonClick,
          );
        }

        scrollToHighlightedText();
        const scrollLocked = document.querySelector(
          '[data-cke-tooltip-text="Unlock scroll"]'
        );
        const individualScroll=scrollLocked?false:true
        scrollToHighlightedText("translate",individualScroll);
      }
    );
  }



  

  return (
    <div className="w-full flex bg-hc-gray ">
    {glossarySectionToggle && (
       <Glossary 
       selectedGlossary={selectedGlossary}
       setSelectedGlossary={setSelectedGlossary}
        chapter={chapter} setGlossarySectionToggle={setGlossarySectionToggle}
        setAppliedGlossaries={setAppliedGlossaries}
        appliedGlossaries={
          appliedGlossaries
        }
        applyGlossary={applyGlossary}
        setAfterHandledUnsavedChangesAction={setAfterHandledUnsavedChangesAction}
        fetchGlossarySuggestionsData={fetchGlossarySuggestionsData}
        textToAddInGlossary={textToAddInGlossary}
        setTextToAddInGlossary={setTextToAddInGlossary}
        sourceEditor={sourceEditor}
        handleChapterChange={handleChapterChange}
        fetchTranslatedChapterPending={fetchTranslatedChapterPending}
        translateEditor={translateEditor}
        setTranslatedContent={setTranslatedContent}
        translatedContent={translatedContent}
        setIsLayoutFixed={setIsLayoutFixed}
        individualScroll={individualScroll}
        />
      )}
    <div
      id="bookEditorContainer"
      className={cn(
        "bg-hc-gray w-full relative  mt-[132px] flex hct-book-editor__container",
        !individualScroll && "overflow-hidden overflow-y-auto",
        glossarySectionToggle && " overflow-auto overflow-x-scroll ml-4",
        individualScroll && glossarySectionToggle && "overflow-y-hidden"
      )}
    >  
      
      <div
        id="sourceContainer"
        className={cn(
          "w-[50%] h-[max-content]  middleLine py-12",
          "flex justify-center",
          individualScroll
            ? "h-[var(--hct-app-editor-height)] overflow-auto"
            : "overflow-x-auto hideSourceScrollBar ",
         glossarySectionToggle && " shrink-0 w-auto  px-10"
            
        )}
      >
        <SourceEditor
          sourceContentData={sourceContentData}
          editorConfig={editorConfig}
          setSourceEditorr={setSourceEditorr}
          setIsSourceEditorReady={setIsSourceEditorReady}
          sourceWordCountData={sourceWordCountData}
          translatedChapterInfo={translatedChapterInfo}
          currentChapter={currentChapter}
          handleFindAndReplaceWordOverBook={handleFindAndReplaceWordOverBook}
          sourceEditor={sourceEditor}
          setTextToAddInGlossary={setTextToAddInGlossary}
          textToAddInGlossary={textToAddInGlossary}
          glossarySectionToggle={glossarySectionToggle}
          individualScroll={individualScroll}
          setGlossarySectionToggle={setGlossarySectionToggle}
        />
      </div>
      <div
        id="translatedContainer"
        className={cn(
          "w-[50%] h-[max-content]  relative py-12",
          "flex justify-center",
          individualScroll
            ? "h-[var(--hct-app-editor-height)] overflow-auto"
            : "overflow-x-auto hideTranslatedScrollBar",
           glossarySectionToggle && "  shrink-0 w-auto px-10"
        )}
      >
        <TranslateEditor
          translatedChapterInfo={translatedChapterInfo}
          setAfterHandledUnsavedChangesAction={
            setAfterHandledUnsavedChangesAction
          }
          handleChapterChange={handleChapterChange}
          editorConfig={editorConfig}
          translatedContentData={translatedContentData}
          translateWordCountData={translateWordCountData}
          currentChapter={currentChapter}
          setTranslateEditor={setTranslateEditor}
          setIsTranslatedEditorReady={setIsTranslatedEditorReady}
          translateEditor={translateEditor}
          toggleDialogBox={toggleDialogBox}
          chaptersTranslationStatusList={chaptersTranslationStatusList}
          setChaptersTranslationStatusList={setChaptersTranslationStatusList}
          isLayoutFixed={isLayoutFixed}
          sourceEditor={sourceEditor}
          handleFindAndReplaceWordOverBook={handleFindAndReplaceWordOverBook}
          handleNextButtonClick={handleNextButtonClick}
          handlePrevButtonClick={handlePrevButtonClick}
          findOverBookData={findOverBookData}
        />
      </div>

      {dialogOpen && (
        <DialogBox
          message={
            "This chapter is now being translated. An email will be sent once it has been completed."
          }
          onClose={toggleDialogBox}
        />
      )}      

      {applyingGlossaryWarningPopup  &&<AlertDialog
          message={
          "Proceeding with these edits or reviews will overwrite the glossary translation enhancement process. "
          }
          onSave={()=>{
            const undoButton = document.querySelector(
              '[data-cke-tooltip-text="Undo (Ctrl+Z)"]'
            );
            undoButton?.click()
            setApplyingGlossaryWarningPopup(false)
          }}
          onDiscard={()=>{
           
            dispatch(setIsGlossaryApplying({status:false,englishWord:""}))
            setApplyingGlossaryWarningPopup(false)
          }}
          type={"applyingGlossary"}
        />
        }
        
    </div>
    </div>
  );
}

export default BookEditor;
