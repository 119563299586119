import React from 'react';
import { useSelector } from 'react-redux';
import { styled, Tooltip } from "@mui/material";
import { ReactComponent as GlossaryDeleteIcon } from "../../assets/glossaryDeleteIcon.svg";
import { ReactComponent as GlossaryEditIcon } from "../../assets/glossaryEditIcon.svg";
import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";
import { getUserNameFromEmail } from '../../utils/translationUtil';

const GlossarySummaryCard = ({ description, handleEditGlossary, handleDeleteVariant, chapter }) => {
    const { userOpenedBookInfo } = useSelector((state) => state.main);
    const { instructions,replacement_dutch_word, metadata_storage, variant_scope } = description ?? {};
    const { exception, whenToApply } = instructions ?? {};
    const { creation_user } = metadata_storage ?? {};
    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(() => ({
        [`& .MuiTooltip-tooltip`]: {
          backgroundColor: "black",
          color: "white",
        },
        "& .MuiTooltip-arrow": {
          color: "black", // Arrow color
        },
      }));
    
      const getScope = (scope) => {
        if (!scope) return "None";
        switch (scope) {
          case "book":
            return `Book : ${
              userOpenedBookInfo.title ?? chapter?.book_info?.book_title
            }`;
          case "author":
            return `Author : ${
              userOpenedBookInfo?.author ?? chapter?.book_info?.author_name
            }`;
          case "genre":
            return `Genre : ${userOpenedBookInfo?.genre ?? "Romance"}`;
          case "language":
            return `Language : ${userOpenedBookInfo?.translatedTo ?? "Dutch"}`;
        }
      };

    return (
        <div className='bg-white mb-2 p-3 rounded-md'>
            <div className="flex justify-between items-center">
                <div style={{ fontFamily:'Times New Roman' }} className='text-base font-normal lowercase'>{replacement_dutch_word}</div>
                <div style={{
                    borderRadius:"20px",
                    border:"1px solid #757575",
                    padding:'2px 10px',
                    fontSize:'12px',
                    fontWeight:"500",
                    color:"#6F6F6F"
                }}>
                    {getScope(variant_scope?.toLowerCase())}
                </div>
            </div>
            <div className='flex flex-col gap-2 mt-2'>
                <div className='flex flex-col gap-1'>
                    <div className="text-[#475467] text-[10px] font-normal leading-normal">
                        When to apply:
                    </div>
                    <div className='text-black font-poppins text-[12px] font-medium leading-normal'>
                        {whenToApply}
                    </div>
                </div>
                <div className='flex flex-col gap-1'>
                    <div className='text-[#475467] text-[10px] font-normal leading-normal'>When to not apply:</div>
                    <div className='text-black font-poppins text-[12px] font-medium leading-normal'>
                        {exception ? exception: 'None'}
                    </div>
                </div>
            </div>
            <div className='flex gap-2 justify-end'>
            <CustomTooltip
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "black", // Change to your desired color
                      color: "white", // Optional: Change text color
                    },
                  }}
                  placement="top-end"
                  arrow
                  title={
                    creation_user &&
                    `Added by: ${getUserNameFromEmail(creation_user)}`
                  }
                >
                  <GlossaryInfoIcon className="cursor-pointer w-[14px] h-[14px]" />
                </CustomTooltip>
                <GlossaryDeleteIcon onClick={()=>handleDeleteVariant(replacement_dutch_word)} className="cursor-pointer text-[#999898] w-[14px] h-[14px]"/>
                <GlossaryEditIcon onClick={()=>handleEditGlossary(description)} className="cursor-pointer text-[#999898] w-[14px] h-[14px]"/>
            </div>
        </div>
    )
}

export default GlossarySummaryCard;
