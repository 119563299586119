import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import GlossaryMatches from "../GlossaryMatches/GlossaryMatches";
import { ReactComponent as GlossaryIcon } from "../../assets/glossary.svg";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import { ReactComponent as WhiteAddGlossaryIcon } from '../../assets/whiteAddGlossaryIcon.svg';
import { ReactComponent as AddGlossaryWhiteIcon } from "../../assets/addGlossaryWhiteIcon.svg";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import { ReactComponent as ChevronRight } from "../../assets/chevronRight.svg";
import { ReactComponent as GlossaryStarIcon } from "../../assets/glossaryStarIcon.svg";
import { ReactComponent as BackArrowIcon } from "../../assets/bigBackArrow.svg";
import { toast } from "react-toastify";
import {
  applySelectedGlossary,
  clearFindResults,
  getGlossaryItem,
  updateGlossarytoTheBook,
} from "../../utils/glossaryUtils";
import GlossaryAccordion from "../GlossaryAccordion/GlossaryAccordion";
import { Button, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as GlossarySearchIcon } from "../../assets/glossarySearchIcon.svg";
import debounce from "lodash.debounce";
import GlossaryWarningModal from "../glosssaryWarningModal/GlossaryWarningModal";
import store from "../../App/store";
import { styled, Tooltip } from "@mui/material";
import GlossarySuggestion from "../GlossarySuggestions/GlossarySuggestion";
import { useDispatch, useSelector } from "react-redux";
import { faCropSimple } from "@fortawesome/free-solid-svg-icons";
import GlossaryScopePopup from "../glossaryScopePopup/GlossaryScopePopup";
import { setGlossaryAppliedData, setGlossaryList, setGlossaryScreenType } from "../../App/editor/editor.actions";
import { useParams } from "react-router-dom";
import GlossaryAccordionComponent from "../AccordionGlossary/GlossaryAccordionComponent";
import GlossaryFormComponent from "../GlossaryForm/GlossaryFormComponent";

function Glossary({
  chapter,
  selectedGlossary,
  setSelectedGlossary,
  applyGlossary,
  setAfterHandledUnsavedChangesAction,
  fetchGlossarySuggestionsData,
  textToAddInGlossary,
  setTextToAddInGlossary,
  sourceEditor,
  handleChapterChange,
  fetchTranslatedChapterPending,
  translateEditor,
  setTranslatedContent,
  setIsLayoutFixed,
  translatedContent,
  setGlossarySectionToggle,
  individualScroll
}) {
  const [openAddToGlossarySection, setOpenAddToGlossarySection] = useState(
    textToAddInGlossary?.text ? true : false
  );
  const [englishWord, setEnglishWord] = useState("");
  const [bookGlossaryData, setBookGlossaryData] = useState([]);
  const [selectedEnglishWord, setSelectedEnglishWord] = useState("");
  const [openedGlossaryItem, setOpenedGlossaryItem] = useState("");
  const [openedGlossaryData, setOpenedGlossaryData] = useState("");
  const [loading, setLoading] = useState(false);
  const [glossryLoadingState, setGlossryLoadingState] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [glossarySearchInput, setGlossarySearchInput] = useState("");
  const [glossaryFilteredData, setGlossaryFilteredData] = useState([]);
  const [sectionType, setSectionType] = useState("glossary");
  const params=useParams()
  const { glossarySuggestionData ,isGlossaryApplying,glossaryScreenType} = useSelector((state) => state.editor);
  const [glossarySuggestionFilteredData, setGlossarySuggestionFilteredData] =
    useState("");
  const [currentGlossary, setCurrentGlossary] = useState('');
  const [showGlossaryForm, setShowGlossaryForm] = useState(false);
  const [glossaryOperationType, setGlossaryOperationType] = useState('');
  const [glossaryToEdit, setGlossaryToEdit] = useState({});
  const dispatch = useDispatch();
  // const [showInputFeildError, setShowInputFeildError] = useState(false);

  // const handleInputChange = (e) => {
  //   console.log(e.target.value, "value");
  //   setShowInputFeildError(false);
  //   setEnglishWord(e.target.value);
  // };
  useEffect(() => {
   
    let addToGlossarySection; 
    if (textToAddInGlossary.text || glossaryScreenType==="glossaryMatches") {
      addToGlossarySection=true;
    }
    if(glossaryScreenType==="glossaryList"){
      addToGlossarySection=false
      clearFindResults(sourceEditor)
    }
    console.log("glossaryScreenType",sourceEditor,glossaryScreenType,addToGlossarySection)
    setOpenAddToGlossarySection(addToGlossarySection)
  }, [textToAddInGlossary,glossaryScreenType]);

  const getBookGlossaryData = async () => {
    if (chapter?.translation_id) {
      setGlossryLoadingState(true);
      const glossaryItems = await getGlossaryItem(chapter?.translation_id,chapter?.translation_info?.translated_to?.toLowerCase() || "dutch");
      glossaryItems?.sort((a, b) => {
        if (a.english_word < b.english_word) return -1;
        if (a.english_word > b.english_word) return 1;
        return 0;
      });
      setBookGlossaryData(glossaryItems);
      dispatch(setGlossaryList(glossaryItems ?? []));
      setGlossryLoadingState(false);
    }
  };

  useEffect(() => {
    getBookGlossaryData();
  }, [chapter]);

  const handleCloseGlossary = () => {
    const glossaryButton = document.querySelector(
      '[data-cke-tooltip-text="Glossary"]'
    );
    console.log("drcalling",glossaryButton)
    // const findAndReplaceEditing = sourceEditor.plugins.get(
    //   "FindAndReplaceEditing"
    // );

    // findAndReplaceEditing?.state.clear(sourceEditor.model);
    // findAndReplaceEditing?.stop();
    glossaryButton?.click();
    dispatch(setGlossaryScreenType(""))
  };

  const handleApplyGlossary = async (operationType) => {
    if (store.getState().editor.isGlossaryApplying.status) {
      toast(`Please wait for the previous glossary process to complete.`, {
        type: "warning",
        autoClose: 3000,
      });
      return;
    }

    if (!openedGlossaryData?.english_word) 
      return;
    

    let selectedGlossaryItem = bookGlossaryData.find(
      (glossary) => glossary?.english_word === openedGlossaryData?.english_word
    );
    if (!selectedGlossaryItem) return;


    // const glossaryPayload = {
    //   author: chapter.book_info.author_name,
    //   language: "dutch",
    //   genre: chapter.book_info.genre,
    //   english_word: selectedGlossaryItem.english_word,
    //   dutch_word_array: selectedGlossaryItem.dutch_word_array,
    //   glossary_item_scope: scope,
    //   application_scope: "book_level",
    //   chapter_numbers: [],
    //   is_active: true,
    //   is_part_of_collection: false,
    //   application_history: null,
    // };
    setSelectedGlossary({ englishWord: "", variant: "" });
    const isTranslatedContentEdited =
      store?.getState()?.editor?.isTranslationContentEdited;
    if (isTranslatedContentEdited) {
      setAfterHandledUnsavedChangesAction({
        actionName: "applyGlossary",
        actionData: {
          // glossaryPayload,
          applicationType: "glossary",
          selectedGlossaryItem,
          // selectedGlossary,
          chapterData: chapter,
          operationType:operationType
        },
      });
      return;
    }
    setLoading(true);
    await applyGlossary({
      // glossaryPayload,
      applicationType:"glossary",
      chapterData:chapter,
      selectedGlossaryItem,
      operationType
     }
    );
    setLoading(false);
  };

  const handlePopoverVisibility = (type) => {
    const glossaryElem = document.querySelector(".glossaryContainer");
    if (type === "close") {
      setIsPopoverOpen(false);
      glossaryElem.style.zIndex = "0";
      return;
    }

    if (isPopoverOpen) {
      glossaryElem.style.zIndex = "0";
    } else {
      glossaryElem.style.zIndex = "10";
    }

    setIsPopoverOpen(!isPopoverOpen);
  };

  useEffect(() => {
    setGlossaryFilteredData(bookGlossaryData);
    setGlossarySuggestionFilteredData(glossarySuggestionData);
    setGlossarySearchInput("");
  }, [bookGlossaryData, glossarySuggestionData, sectionType]);

  const debouncedGlossarySearch = useCallback(
    debounce((searchInput) => {
      if (searchInput) {
        const lowercasedSearchTerm = searchInput.trim().toLowerCase();

        if (sectionType === "glossary") {
          const newFilteredData = bookGlossaryData.filter((glossary) => {
            if (
              glossary.english_word.toLowerCase().includes(lowercasedSearchTerm)
            )
              return true;

            let isVariantFound = false;
            glossary.dutch_word_array.forEach((variant) => {
              if (
                variant.replacement_dutch_word
                  .toLowerCase()
                  .includes(lowercasedSearchTerm)
              )
                isVariantFound = true;
            });

            return isVariantFound;
          });
          setGlossaryFilteredData(newFilteredData);
        }

        if (sectionType === "suggestions") {
          const newFilteredData = glossarySuggestionData.filter((glossary) => {
            if (
              glossary.english_word.toLowerCase().includes(lowercasedSearchTerm)
            )
              return true;
            if (
              glossary.dutch_word_array[
                glossary.applied_index
              ].replacement_dutch_word
                .toLowerCase()
                .includes(lowercasedSearchTerm)
            )
              return true;
          });
          setGlossarySuggestionFilteredData(newFilteredData);
        }
      } else {
        sectionType === "glossary" && setGlossaryFilteredData(bookGlossaryData);
        sectionType === "suggestions" &&
          setGlossarySuggestionFilteredData(glossarySuggestionData);
      }
    }, 500),
    [bookGlossaryData, glossarySuggestionFilteredData, sectionType]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setGlossarySearchInput(value);
    debouncedGlossarySearch(value);
  };

  const handleCloseAddToGlossarySection = () => {
    setOpenAddToGlossarySection(false);
    setSelectedEnglishWord("");
    getBookGlossaryData();
    setGlossarySearchInput("");
    setTextToAddInGlossary("");
  };


  const getCurrentChapterParagraph=(currentChapter, prevParagraph,paragraphAfterGlossary)=>{
     const parser = new DOMParser();
     const prevParagraphElement=parser.parseFromString(prevParagraph, "text/html");
     const paragraphAfterGlossaryElement=parser.parseFromString(paragraphAfterGlossary, "text/html");
     const currentChapterElement=parser.parseFromString(currentChapter, "text/html");
     const allTranslatedParagraph= currentChapterElement.querySelectorAll('body > p')
     let matchedPara=""
     allTranslatedParagraph.forEach((paragraph) => {
          if(paragraph.textContent?.trim()===prevParagraphElement?.body?.textContent?.trim()){
            console.log(paragraph.outerHTML,"matched")
            matchedPara= paragraph.outerHTML;
          }


          console.log("matched chapterPara",paragraph.textContent)
          console.log("matched para",paragraphAfterGlossaryElement?.body?.textContent)


          if(paragraph.textContent?.trim()===paragraphAfterGlossaryElement?.body?.textContent?.trim()){
            // console.log(paragraph.outerHTML,"matched")
             matchedPara= paragraph.outerHTML;
          }
     })
    return matchedPara?.trim()
  }

  useEffect(() => {
    const updateGlossaryAppliedData = async () => {
      if (fetchTranslatedChapterPending) return;
      if(isGlossaryApplying.status){
        translateEditor.enableReadOnlyMode(translateEditor.id);
      }else{
        translateEditor.disableReadOnlyMode(translateEditor.id);
      }
      
      const docRef = doc(firestore, "live_glossary_application",params.id);
      const unsubscribe = onSnapshot(docRef, (doc) => {
      
        
        let newData ={};
        if(doc.exists())
          newData={ id: doc.id, ...doc.data()}

         
        console.log("newData[0]",newData)
        const currentBookData = newData ?? {};
        if(!currentBookData?.english_word) {
          dispatch(setGlossaryAppliedData({}));
          document.documentElement.style.removeProperty(
            `--glossary-changes-bg`,
            "#3B37FF26"
          );
          return;
        }
        let formatedData = {};
        // if (isGlossaryApplying.status) {

          formatedData[currentBookData?.english_word] = {
            glossaryItemDetails: {
              english_word: currentBookData.english_word,
              glossary_item_id: currentBookData.glossary_item_id,
            },
            affectedChaptersData: currentBookData.updated_resources,
            status: "Applying",
          };
          
          dispatch(setGlossaryAppliedData(formatedData));
          document.documentElement.style.setProperty(
            `--glossary-changes-bg`,
            "#3B37FF26"
          );
          const applyingGlossaryData =
            formatedData?.[isGlossaryApplying.englishWord || Object?.keys(formatedData)?.[0]]?.affectedChaptersData || {};
          console.log(applyingGlossaryData,Object?.keys(formatedData),"applyingGlossaryData",formatedData)
          let currentChapter = translateEditor?.getData();
          let currentChapterPara=""
          Object.keys(applyingGlossaryData)?.forEach((occuranceNo) => {
            let changedParagraphData = applyingGlossaryData[occuranceNo];
            if(!changedParagraphData?.final_translated_paragraph_with_tags?.trim()) return 
            currentChapterPara = getCurrentChapterParagraph(
              currentChapter,
              changedParagraphData?.paragraph_before_glossary,
              changedParagraphData?.paragraph_after_glossary
            );
            console.log(
              "matched check changedParagraphData",
              currentChapterPara
            );
            console.log(
              "matched check latest para",occuranceNo,"----",
              changedParagraphData?.final_translated_paragraph_with_tags
            );
            console.log(
              "matched protection",
              currentChapter &&
              currentChapterPara
            );
            let currentChapterNo = store.getState().editor.currentChapter;    
            if (
              currentChapter &&
              currentChapterPara &&
              (Number(changedParagraphData.chapter_number) ===
                Number(currentChapterNo))
            ) {
              console.log(
                "currentChapter currentChapterPara",
                currentChapterPara
              );
              currentChapter = currentChapter?.replace(
                currentChapterPara,
                changedParagraphData?.final_translated_paragraph_with_tags
              );
              console.log("******currentChapter after changes ****",occuranceNo, currentChapter);
            }
          });
          if(currentChapter){
            setTimeout(()=>{
              console.log("final chapter",currentChapter)
              setTranslatedContent(currentChapter+" ");
              setIsLayoutFixed(false);
            },100)
          }
        // }
      });
      return () => unsubscribe();
    };
    updateGlossaryAppliedData();
  }, [isGlossaryApplying, fetchTranslatedChapterPending]);

  const addVariantToWord = (englishWord) =>{
    setShowGlossaryForm(true);
    setSelectedEnglishWord(englishWord);
    setGlossaryOperationType('add')
  }

  const handleEditGlossary = (glossaryToEdit) => {
    setShowGlossaryForm(true);
    setGlossaryOperationType('edit');
    setGlossaryToEdit(glossaryToEdit);
  }

  return (
    <div
      style={{ height: "calc(100dvh - 133px - 71px)" }}
      className="w-[576px] shrink-0 mt-[150px] rounded-md  relative ml-4 "
    >
      {!openAddToGlossarySection && (
        <div
          style={{ height: "calc(100dvh - 133px - 71px)" }}
          className={`w-[576px] glossaryContainer shrink-0 top-[100px] left-4  fixed py-2 gap-3 flex  ${
           "pb-[10px]"
          }  flex-col px-3 bg-[#FFFFFF] my-12  rounded-md`}
        >
          {!showGlossaryForm && (
            <div className="flex justify-between">
              <div className="flex items-center gap-4 ">
                <span className="text-[#1E1C80] font-poppins text-base font-normal leading-normal">
                  Glossary Entries
                </span>
              </div>
              <div className="flex items-center gap-3">
                <Button
                  variant="contained"
                  className="gap-2 text-xs rounded px-3 py-[6px]"
                  onClick={() => {
                    setGlossaryOperationType("add");
                    setShowGlossaryForm(true);
                    setSelectedEnglishWord("");
                    setGlossaryToEdit({});
                  }}
                >
                  <WhiteAddGlossaryIcon className="text-white" />
                  Add to Glossary
                </Button>
                <CloseIcon
                  className="cursor-pointer w-5 h-5"
                  onClick={handleCloseGlossary}
                />
              </div>
            </div>
          )}

          {!showGlossaryForm && (
            <>
              <div className="text-[#1E1C80] font-poppins text-sm mt-1 font-normal leading-normal">
                Total Glossaries ({glossaryFilteredData?.length})
              </div>
              <div
                // style={{ borderBottom: "1px solid #BFBFBF" }}
                className="flex items-center gap-5 w-full"
              >
                <div
                  style={{ border: "1px solid #A6A6A6" }}
                  className="flex text-[10px] w-full px-2 py-[6px] items-center rounded gap-2"
                >
                  <GlossarySearchIcon className="w-4 h-5" />
                  <input
                    value={glossarySearchInput}
                    onChange={handleSearchChange}
                    placeholder="Search..."
                    type="text"
                    maxLength={100}
                    className="border-none w-full outline-none"
                  />
                </div>
              </div>
            </>
          )}
          {showGlossaryForm && (
            <GlossaryFormComponent
              chapter={chapter}
              operationType={glossaryOperationType}
              setBookGlossaryData={setBookGlossaryData}
              bookGlossaryData={bookGlossaryData}
              setShowGlossaryForm={setShowGlossaryForm}
              englishWord={selectedEnglishWord}
              glossaryToEdit={glossaryToEdit}
              setGlossaryToEdit={setGlossaryToEdit}
              setSelectedEnglishWord={setSelectedEnglishWord}
              setGlossaryOperationType={setGlossaryOperationType}
              handleCloseGlossary={handleCloseGlossary}
            />
          )}

          {!showGlossaryForm && (
            <>
              {sectionType === "glossary" ? (
                <div
                  style={{ height: "calc(100dvh - 133px - 71px)" }}
                  className=" overflow-y-scroll hideSourceScrollBar"
                >
                  {
                    // bookGlossaryData.length > 0 ?
                    glossryLoadingState && !glossaryFilteredData?.length ? (
                      <div className="w-full text-center text-[#5F6368] text-sm pt-10">
                        Loading...
                      </div>
                    ) : glossarySearchInput && !glossaryFilteredData?.length ? (
                      <div className="w-full justify-center items-center flex-col flex pt-4 gap-2">
                        <p className="text-[#5F6368] text-  [10px] m-0">
                          Opps!
                        </p>

                        <p className="text-[#CF1322] text-[14px] m-0">
                          Searched glossary does not exist
                        </p>

                        <Button
                          onClick={() => {
                            // if (!englishWord) {
                            //   setShowInputFeildError(true);
                            //   return;
                            // }
                            // setOpenAddToGlossarySection(true);
                            setSelectedEnglishWord(glossarySearchInput);
                            setShowGlossaryForm(true);
                            setGlossaryOperationType("add");
                          }}
                          variant="outlined"
                          className="flex rounded-[4px] text-[12px] py-[6px] px-3 gap-2"
                        >
                          Add it to Glossary <AddGlossaryIcon />
                        </Button>
                      </div>
                    ) : !glossaryFilteredData?.length ? (
                      <div className="w-full text-center text-[#5F6368] text-sm pt-10">
                        Loading...
                      </div>
                    ) : (
                      glossaryFilteredData.map((glossaryItem) => {
                        return (
                          <GlossaryAccordionComponent
                            key={glossaryItem?.id}
                            chapter={chapter}
                            glossaryItem={glossaryItem}
                            currentGlossary={currentGlossary}
                            setCurrentGlossary={setCurrentGlossary}
                            addVariantToWord={addVariantToWord}
                            handleEditGlossary={handleEditGlossary}
                            bookGlossaryData={bookGlossaryData}
                            setBookGlossaryData={setBookGlossaryData}
                          />
                          // <GlossaryAccordion
                          //   key={glossaryItem?.english_word}
                          //   chapter={chapter}
                          //   glossaryItem={glossaryItem}
                          //   // englishWord={englishWord}
                          //   getBookGlossaryData={getBookGlossaryData}
                          //   setSelectedEnglishWord={setSelectedEnglishWord}
                          //   setOpenAddToGlossarySection={
                          //     setOpenAddToGlossarySection
                          //   }
                          //   setOpenedGlossaryItem={setOpenedGlossaryItem}
                          //   openedGlossaryItem={openedGlossaryItem}
                          //   setSelectedGlossary={setSelectedGlossary}
                          //   selectedGlossary={selectedGlossary}
                          //   // setShowInputFeildError={setShowInputFeildError}
                          // />
                        );
                      })
                    )
                  }
                </div>
              ) : glossarySearchInput &&
                !glossarySuggestionFilteredData.length ? (
                <div className="w-full justify-center items-center flex-col flex pt-4 gap-2">
                  <p className="text-[#5F6368] text-  [10px] m-0">Opps!</p>

                  <p className="text-[#CF1322] text-[14px] m-0">
                    Searched glossary suggestion does not exist
                  </p>
                </div>
              ) : (
                <GlossarySuggestion
                  applyGlossary={applyGlossary}
                  glossarySuggestionData={glossarySuggestionFilteredData}
                  chapter={chapter}
                  fetchGlossarySuggestionsData={fetchGlossarySuggestionsData}
                  setAfterHandledUnsavedChangesAction={
                    setAfterHandledUnsavedChangesAction
                  }
                />
              )}
            </>
          )}

          {/* {openAddToGlossarySection && (
          <AddToGlossary
            onClose={() => {
              setOpenAddToGlossarySection(false);
              setSelectedEnglishWord("");
              getBookGlossaryData();
              setGlossarySearchInput("");
            }}
            // englishWord={englishWord}
            glossarySearchInput={glossarySearchInput}
            chapter={chapter}
            glossaryData={bookGlossaryData}
            selectedEnglishWord={selectedEnglishWord}
            setGlossarySearchInput={setGlossarySearchInput}
          />
        )} */}
        </div>
      )}
      {openAddToGlossarySection && (
        <GlossaryMatches
          onClose={handleCloseAddToGlossarySection}
          glossarySearchInput={glossarySearchInput}
          highlightedEnglish={textToAddInGlossary}
          chapter={chapter}
          glossaryData={bookGlossaryData}
          selectedEnglishWord={selectedEnglishWord}
          setGlossarySearchInput={setGlossarySearchInput}
          handleCloseGlossary={handleCloseGlossary}
          getBookGlossaryData={getBookGlossaryData}
          handleApplyGlossary={handleApplyGlossary}
          handlePopoverVisibility={handlePopoverVisibility}
          isPopoverOpen={isPopoverOpen}
          setSelectedGlossary={setSelectedGlossary}
          selectedGlossary={selectedGlossary}
          setSelectedEnglishWord={setSelectedEnglishWord}
          sourceEditor={sourceEditor}
          handleChapterChange={handleChapterChange}
          fetchTranslatedChapterPending={fetchTranslatedChapterPending}
          setOpenedGlossaryData={setOpenedGlossaryData}
          openedGlossaryData={openedGlossaryData}
          translateEditor={translateEditor}
          setIsLayoutFixed={setIsLayoutFixed}
          setTranslatedContent={setTranslatedContent}
          setGlossarySectionToggle={setGlossarySectionToggle}
          individualScroll={individualScroll}
        />
      )}
    </div>
  );
}

export default Glossary;
