import React from "react";
import parse ,{ domToReact } from "html-react-parser";

import {Tooltip, Typography, styled } from "@mui/material";

function GlossaryAffectedSentence({openedGlossaryData,iteration}) {

 const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(() => ({
        [`& .MuiTooltip-tooltip`]: {
          backgroundColor: "white",
          color: "black",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
          padding:"0"
        //   width:"170px"
        },
        "& .MuiTooltip-arrow": {
          color: "white", // Arrow color
        },
 }));
  const extractPlainText = (domNode) => {
    if (!domNode) return "";

    // If the node is text, return its value
    if (domNode.type === "text") return domNode.data;

    // If the node has children, recursively get their text content
    if (domNode.children) {
      return domNode.children.map(extractPlainText).join("");
    }

    return "";
  };

  const replaceSpans = (htmlContent) => {
    return parse(htmlContent, {
      replace: (domNode) => {
        try {
            if (
                domNode.name === "span" &&
                (domNode.attribs?.class === "added" ||
                  domNode.attribs?.class === "skipped" ||
                  domNode.attribs?.class === "removed"
                )
              ) {
                const innerText = extractPlainText(domNode);
                let selectedVariant =
                  openedGlossaryData?.dutch_word_array?.find(
                    (variant) =>
                      innerText?.trim()?.toLowerCase() ===
                        variant?.replacement_dutch_word?.toLowerCase() ||
                      domNode?.attribs?.variant_used?.trim()?.toLowerCase() ===
                        variant?.replacement_dutch_word?.toLowerCase()
                  );
                
                // if(!selectedVariant){
                //   const parser = new DOMParser();
                //   const affectedSentenceElem = parser.parseFromString(
                //     htmlContent,
                //     "text/html"
                //    );
                //    const spanList =
                //    affectedSentenceElem.querySelectorAll("span.added");
                //    spanList.forEach((span) => {
                //     const attributeValue = span?.getAttribute("variant_used") || "none";
                //       selectedVariant = openedGlossaryData.dutch_word_array.find(
                //       (variant) => (attributeValue.trim().toLowerCase() === variant.replacement_dutch_word.toLowerCase())
                //     );
                    
                //   });
                // }
                
                if (selectedVariant) {
                  // You can check for a specific class or attribute here
                  return (
                    <CustomTooltip
                     placement="top"
                     title={<React.Fragment>
                      <div className="flex flex-col gap-2 p-3">
                      <div className="flex flex-col -mt-1 gap-[2px]">
                                <span className="text-[12px] text-[#475467] font-[Poppins]">
                                  When to apply:
                                </span>
                                <span className="text-[12px] text-[#242424] font-[Poppins]">
                                  {selectedVariant?.instructions?.whenToApply?.trim() ||
                                    "None"}
                                </span>
                              </div>
      
                              <div className="flex flex-col gap-[2px]">
                                <span className="text-[12px] text-[#475467] font-[Poppins]">
                                  When to not apply:
                                </span>
                                <span className="text-[12px] font-[Poppins] ">
                                  {selectedVariant?.instructions?.exception?.trim() || "None"}
                                </span>
                              </div>
                      </div>
                    </React.Fragment>} arrow>
                      <span  className={`${domNode.attribs?.class} cursor-pointer`}>
                        {domToReact(domNode.children, replaceSpans)}
                      </span>
                    </CustomTooltip>
                  );
                }else if(domNode?.attribs?.variant_used){
                  return (
                    <CustomTooltip
                     placement="top"
                     title={<React.Fragment>
                      <div className="flex text-[12px] p-3">Variant does not exists</div>
                    </React.Fragment>} arrow>
                      <span  className={`${domNode.attribs?.class} cursor-pointer`}>
                        {domToReact(domNode.children, replaceSpans)}
                      </span>
                    </CustomTooltip>
                  );
                }
              }
        } catch (error) {
            console.log("error in parsing sentence",error)
        }
        
      },
    });
  };
  return (
    <div
      style={{
        borderBottom: "1px solid #E3E3E3",
      }}
      className="px-2 pb-2 changes w-full"
      // dangerouslySetInnerHTML={{
      //   __html:
      //     iteration?.latestTranslatedText ||
      //     "Change not found",
      // }}
    >
      {iteration?.latestTranslatedText
        ? replaceSpans(iteration?.latestTranslatedText)
        : "Change not found"}
    </div>
  );
}

export default GlossaryAffectedSentence;
