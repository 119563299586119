import React, { useEffect, useState } from "react";
import "./AccordianGlossary.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as GlossaryDeleteIcon } from "../../assets/glossaryDeleteIcon.svg";
import { ReactComponent as GlossaryEditIcon } from "../../assets/glossaryEditIcon.svg";
import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import { ReactComponent as GlossaryChevronUp } from "../../assets/glossaryChevronUp.svg";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";

import { Button, TextField, Tooltip, styled } from "@mui/material";
import AddGlossaryForm from "../AddGlossaryForm/AddGlossaryForm";
import { getCapitalisedString, getUserNameFromEmail } from "../../utils/translationUtil";
import { useDispatch, useSelector } from "react-redux";
import { setGlossaryList } from "../../App/editor/editor.actions";
import { getDiffContent } from "../../utils/glossaryUtils";
import store from "../../App/store";

function AccordianGlossary({
  glossary,
  openedGlossaryData,
  chapter,
  setGlossaryMatchesData,
  glossaryMatchesData,
  setOpenedGlossaryData,
  sourceEditor,
  handleUpdateGlossary,
  setSelectedGlossary,
  selectedGlossary,
  glossaryData,
  updateFindTextResults,
  highlightedEnglishText
}) {
  const [showVariantInputFeild, setShowVariantInputFeild] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { userOpenedBookInfo } = useSelector((state) => state.main);
  const [variantDataForEdit, setVariantDataForEdit] = useState({
    replacement_dutch_word: "",
    scope: "Language",
    whenToApply: "",
    exceptions: "",
  })
  const dispatch = useDispatch();
  console.log("userOpenedBookInfo", userOpenedBookInfo);
  useEffect(() => {
    if (glossary.english_word === openedGlossaryData.english_word){
      setExpanded(true);
    }else{
      setExpanded(false);
    }
  }, [openedGlossaryData]);

  useEffect(() => {
    if (glossary.english_word === openedGlossaryData.english_word){
      setOpenedGlossaryData(glossary)
    }
  }, [])

  // useEffect(() => {
  //   if(chapter){
  //     const updatedChapterNo=store.getState().editor.currentChapter
  //     getDiffingContent(chapter.translation_id,updatedChapterNo)
  //   }
  // }, [])  


  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

  const resetAccordianValues = () => {
    setShowVariantInputFeild(false);
    setOpenedGlossaryData({});
    setSelectedGlossary({
      englishWord: "",
      variant: "",
    });
    setVariantDataForEdit({
      replacement_dutch_word: "",
      scope: "",
      whenToApply: "",
      exceptions: "",
    })

    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    findAndReplaceEditing.find(highlightedEnglishText,{searchText:highlightedEnglishText,matchCase:false,wholeWords:true})
    updateFindTextResults(highlightedEnglishText)
  };

  useEffect(() => {
    if (expanded) {
      const findAndReplaceEditing = sourceEditor.plugins.get(
        "FindAndReplaceEditing"
      );
      // findAndReplaceEditing?.state?.set("matchWholeWords", true);
      // const findText = `\\b${glossary.english_word}\\b`
      // sourceEditor.execute("find", glossary.english_word);
      if(!glossary.english_word || !highlightedEnglishText) return
      findAndReplaceEditing.find(glossary.english_word || highlightedEnglishText,{searchText:glossary.english_word || highlightedEnglishText ,matchCase:false,wholeWords:true})
      updateFindTextResults(glossary.english_word)
    } 
  }, [expanded]);

  const handleChange = () => {
    if (expanded) {
      resetAccordianValues();
    }else{
      setOpenedGlossaryData(glossary)
    }
    setShowVariantInputFeild(false)
    setExpanded(!expanded);
  };

  const getScope = (scope) => {
    if (!scope) return "None";
    switch (scope) {
      case "book":
        return `Book : ${
          userOpenedBookInfo.title ?? chapter?.book_info?.book_title
        }`;
      case "author":
        return `Author : ${
          userOpenedBookInfo?.author ?? chapter?.book_info?.author_name
        }`;
      case "genre":
        return `Genre : ${userOpenedBookInfo?.genre ?? "Romance"}`;
      case "language":
        return `Language : ${userOpenedBookInfo?.translatedTo ?? "Dutch"}`;
    }
  };

  const handleRadioButtonChange = (event) => {
    setSelectedGlossary({
      englishWord: glossary?.english_word,
      variant: event.target.value,
    });
  };

  const handleDeleteVariant = async (variant) => {
    const updatedVariants = await handleUpdateGlossary({
      action: "delete",
      englishText: glossary.english_word,
      variant: variant,
      type: "variant",
    });
 
    let updatedGlossaries = [...glossaryData];
    if (updatedVariants?.length) {
      updatedGlossaries = updatedGlossaries.map((glossaryData) => {
        if (glossaryData.english_word === glossary.english_word) {
          glossaryData.dutch_word_array = updatedVariants;
        }
        return glossaryData;
      });
    } else {
      updatedGlossaries = updatedGlossaries.filter(
        (glossaryData) => glossaryData.english_word !== glossary.english_word
      );
    }

    dispatch(setGlossaryList(updatedGlossaries));
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        borderBottomLeftRadius: "0",
        borderBottomLeftRadius: "0",
        "&:last-of-type": {
          borderRadius: "0px",
        },
        "&::before": {
          height: 0, // Removes the height
        },
        boxShadow: "none",
        borderBottom: "1px solid #BFBFBF",
        "& .MuiAccordion-region": { height: "100%" },
        "& .MuiAccordionSummary-root": {
          // Targeting the accordion summary
          "&.Mui-expanded": {
            // Adjust min-height when expanded
            minHeight: "auto",
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<GlossaryViewIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          display: expanded ? "flex" : "flex",
          borderBottomLeftRadius: "0",
          borderBottomLeftRadius: "0",
          paddingBottom: "0",
          minHeight: "30px",
          height: "45px",
          paddingLeft: "5px",
        }}
      >
        <div className="flex justify-between gap-6 w-full items-center pr-2">
          <span className="text-[18px] w-[50%] text-[#000]">
            {glossary.english_word}
          </span>

          <span className="text-[12px]  text-[#000000]">
            {glossary?.dutch_word_array?.length}{" "}
            {glossary?.dutch_word_array?.length === 1 ? "Variant" : "Variants"}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0",
          border: "none",
          boxShadow: "none",
          borderBottomLeftRadius: "0",
          borderBottomLeftRadius: "0",
          height: "100%",
        }}
      >
        <div className="flex  flex-col gap-2  px-0 pb-2  h-full  w-full">
          {/* <div className="flex justify-between gap-6 w-full items-center pr-2">
            <span className="text-[18px] px-1 w-[50%] text-[#000]">
              {glossary.english_word}
            </span>

            <div
              onClick={handleChange}
              className="flex cursor-pointer items-center gap-2"
            >
              <span className="text-[10px]  text-[#000000]">
                {glossary.dutch_word_array.length}{" "}
                {glossary.dutch_word_array.length === 1
                  ? "Variant"
                  : "Variants"}
              </span>
              <GlossaryChevronUp />
            </div>
          </div> */}

          <div className="flex bg-[#F5F7FF] rounded-[4px] flex-col px-3 py-2 gap-2">
            <div className="text-sm flex justify-between items-center px-[2px] ">
              <span className="text-[#1E1C80]">
                {glossary?.dutch_word_array?.length <= 1
                  ? "Dutch Variant"
                  : "Dutch Variants"}
              </span>
              <div
                onClick={() => setShowVariantInputFeild(true)}
                className="text-[12px] text-[#3B37FF] cursor-pointer flex items-center justify-start gap-1"
              >
                <AddGlossaryIcon className="text-[#3B37FF]" />
                Add more variants
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {showVariantInputFeild && (
                <AddGlossaryForm
                  chapter={chapter}
                  setShowVariantInputFeild={setShowVariantInputFeild}
                  englishWord={glossary.english_word}
                  setGlossaryMatchesData={setGlossaryMatchesData}
                  glossaryMatchesData={glossaryMatchesData}
                  handleUpdateGlossary={handleUpdateGlossary}
                  operationType={variantDataForEdit?.replacement_dutch_word.trim()?"update":"add"}
                  setSelectedGlossary={setSelectedGlossary}
                  variantDataForEdit={variantDataForEdit}
                  setVariantDataForEdit={setVariantDataForEdit}
                />
              )}
              {glossary?.dutch_word_array?.map((varient, i) => {
                return varient.replacement_dutch_word!==variantDataForEdit.replacement_dutch_word && (
                  <div
                    style={{ border: "1px solid #3961FF" }}
                    className="py-2 px-[6px] flex flex-col rounded-[4px] bg-white"
                  >
                    <div className="flex items-start pl-2 gap-2">
                      {/* <input
                        value={varient?.replacement_dutch_word}
                        checked={
                          selectedGlossary?.variant ===
                          varient?.replacement_dutch_word
                        }
                        onChange={handleRadioButtonChange}
                        type="radio"
                        className="mt-[6px]"
                      /> */}
                      <div className=" flex gap-2 w-full flex-col">
                        <div className=" flex justify-between w-full items-center ">
                          <span
                            style={{ fontFamily: "Times New Roman" }}
                            className=""
                          >
                            {varient.replacement_dutch_word}
                          </span>
                          <span
                            style={{ border: "1px solid #6F6F6F" }}
                            className="text-[#6F6F6F] text-[10px] font-[500] rounded-xl py-[2px] px-3"
                          >
                            {getScope(varient?.variant_scope?.toLowerCase())}
                          </span>
                        </div>

                        <div className="flex flex-col -mt-1 gap-1">
                          <span className="text-[10px] text-[#475467] font-[Poppins]">
                            When to apply:
                          </span>
                          <span className="text-[12px] font-[Poppins] font-[500]">
                            {varient?.instructions?.whenToApply?.trim() ||
                              "None"}
                          </span>
                        </div>

                        <div className="flex flex-col gap-1">
                          <span className="text-[10px] text-[#475467] font-[Poppins]">
                            When to not apply:
                          </span>
                          <span className="text-[12px] font-[Poppins] font-[500]">
                            {varient?.instructions?.exception?.trim() || "None"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-end  justify-end gap-2">
                      <CustomTooltip
                        sx={{
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "black",
                            color: "white",
                          },
                        }}
                        placement="top-end"
                        arrow
                        title={
                          varient?.metadata_storage?.creation_user &&
                          `Added by: ${getUserNameFromEmail(
                            varient?.metadata_storage?.creation_user
                          )}`
                        }
                      >
                        <GlossaryInfoIcon className="cursor-pointer text-[#999898] w-[14px] h-[14px]" />
                      </CustomTooltip>
                      <GlossaryDeleteIcon
                        onClick={() => {
                          handleDeleteVariant(varient?.replacement_dutch_word);
                          // if (
                          //   dutchVarients.length === 1
                          // ) {
                          //   setDeleteWarningData({
                          //     type: "Last Variant",
                          //     variant: varient?.replacement_dutch_word,
                          //   });
                          //   return;
                          // }
                          // setDeleteWarningData({
                          //   type: "Variant",
                          //   variant: varient?.replacement_dutch_word,
                          // });
                        }}
                        className="cursor-pointer text-[#999898] w-[14px] h-[14px]"
                      />
                      <GlossaryEditIcon
                        onClick={() => {
                          setShowVariantInputFeild(true);
                          setVariantDataForEdit(
                            {
                              replacement_dutch_word: varient?.replacement_dutch_word,
                              scope: getCapitalisedString(varient?.variant_scope?.toLowerCase()),
                              whenToApply: varient?.instructions?.whenToApply.trim(),
                              exceptions: varient?.instructions?.exception,
                            }
                          )
                          // setInputVariant(varient.replacement_dutch_word);
                         
                        }}
                        className="cursor-pointer text-[#999898] w-[14px] h-[14px]"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordianGlossary;
