import React, { useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditGlossaryCard from './EditGlossaryCard/EditGlossaryCard';
import Button from '@mui/material/Button';
import { updateGlossarytoTheBook } from '../../utils/glossaryUtils';
import { toast } from 'react-toastify';

import './EditGlossaryModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setGlossaryList } from '../../App/editor/editor.actions';
import AddGlossaryForm from "../AddGlossaryForm/AddGlossaryForm";
import CircularProgress from '@mui/material/CircularProgress';

const EditGlossaryModal = ({
  showModal,
  setShowModal,
  glossaryData,
  selectedVariant,
  appliedTranslation,
  setOccurrenceNoToEdit,
  isAddVariant,
  chapter,
  handleReplaceGlossary,
  
}) => {
  const [data, setData] = useState();
  const { glossaryList } = useSelector((state) => state.editor);
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState([selectedVariant]); 
  const [inputData, setInputData] = useState({
    replacement_dutch_word: "",
    scope: "",
    whenToApply: "",
    exceptions: "",
  });
  const { loggedInUserEmail } = useSelector((state) => state.main);

  useEffect(() => {
    setData(glossaryData);
  }, [glossaryData]);


  useEffect(() => {
   setExpanded([selectedVariant])
  }, [selectedVariant])

  const bookInfo = {
    author: chapter?.book_info?.author_name,
    language: chapter?.translation_info?.translated_to?.toLowerCase(),
    genre: chapter?.book_info?.genre,
  };
  
  const getNewVariant = (inputData, bookInfo) => {
    return {
      ...bookInfo,
      metadata_storage: {
        "creation date": new Date(),
        creation_user: loggedInUserEmail,
        application_history: [],
      },
      instructions: {
        whenToApply: inputData.whenToApply,
        exception: inputData.exceptions,
      },
      variant_scope: inputData.scope,
      replacement_dutch_word: inputData?.replacement_dutch_word?.trim(),
    };
  };

  const isSubmitDisbaled = useMemo(()=>{
    if(isAddVariant){
      return !inputData?.replacement_dutch_word || !inputData?.scope || !inputData?.whenToApply
    }
    return false
  },[inputData])

  const handleUpdateInputData = (e)=>{
    setInputData({
      ...inputData,
      [e.target.name]: e?.target?.value,
    });
  }
  
  const isVariantExists = () => {
    const variants = data?.dutch_word_array?.map((elem)=>elem?.replacement_dutch_word?.trim()?.toLowerCase());
    if(variants?.includes(inputData?.replacement_dutch_word?.trim()?.toLowerCase())){
      return true
    }
    return false
  }

  const handleSaveChanges = async () => {
    if(loading){
      return
    }
    if(isAddVariant && isVariantExists()){
      toast(`Variant already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return
    }
    const updatedData = {...data};
    if(isAddVariant){
      updatedData.dutch_word_array = [...updatedData.dutch_word_array,getNewVariant(inputData, bookInfo)]
    }
    let payload = {
      ...updatedData,
      glossary_item_scope: "Book",
      application_scope: "book_level",
    };
    setIsLoading(true);
    updateGlossarytoTheBook(payload, data?.id)
      .then((res) => {
        toast.success("Changes saved!");
        // setOccurrenceNoToEdit(0)
        setIsLoading(false);
        setShowModal(false);
        let updatedGlossaries = glossaryList;
        updatedGlossaries = updatedGlossaries.map((glossaryData) => {
          if (glossaryData.english_word === updatedData.english_word) {
            glossaryData.dutch_word_array = updatedData.dutch_word_array;
          }
          return glossaryData;
        });
          
        dispatch(setGlossaryList(updatedGlossaries));
        let addedVariantDetails={}
        const variantIndex = updatedData.dutch_word_array.findIndex(
          (variant) =>
            variant.replacement_dutch_word.toLowerCase() ===
            inputData?.replacement_dutch_word?.trim()?.toLocaleLowerCase()
        );
        console.log("payload isAddVariant", variantIndex,isAddVariant);
        addedVariantDetails={
          englishWord: updatedData?.english_word,
          variant: isAddVariant ? inputData?.replacement_dutch_word?.trim() : selectedVariant,
          variantIndex: isAddVariant ? variantIndex : null,
        }
        handleReplaceGlossary(addedVariantDetails);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Unable to save changes");
      });
  };

  const editGlossaryData = (replacementWord, key, newValue) => {
    setData((prevData) => {
      const updatedDutchWordArray = prevData.dutch_word_array.map((item) => {
        if (item.replacement_dutch_word === replacementWord) {
          return {
            ...item,
            instructions: {
              ...item.instructions,
              [key]: newValue,
            },
          };
        }
        return item;
      });

      return {
        ...prevData,
        dutch_word_array: updatedDutchWordArray,
      };
    });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      // Add the panel to the expanded state if not already present
      setExpanded((prev) => [...prev, panel]);
    } else {
      // Remove the panel from the expanded state
      setExpanded((prev) => prev.filter((item) => item !== panel));
    }
  };


  return (
    <div className="">
      <Dialog
        open={showModal}
        maxWidth="lg"
        fullWidth={true}
        PaperProps={{
          style: {
            maxHeight: "90vh",
            overflowY: "auto",
            width: "662px",
          },
        }}
      >
        <DialogTitle>
          <div className="flex items-center gap-1">
            {
              isAddVariant ? <div className='text-[#1E1C80] font-poppins text-[18px] font-medium leading-normal mb-2'>Add Option</div> : (
                <>
                  <span className="text-[#E14942] text-lg font-[500] line-through">
                    {appliedTranslation}
                  </span>
                  <span className="text-[#3EAF3F] text-lg font-[500]">
                    {selectedVariant}
                  </span>
                </>
              )
            }
          </div>
          {
            isAddVariant && (
              <AddGlossaryForm
                handleUpdateInputData={handleUpdateInputData}
                isAddVariant={isAddVariant}
                chapter={chapter}
              />
            )
          }
          {
            !isAddVariant && (
              <div className="text-sm font-medium mt-4 font-[Poppins]">
                We recommend updating the glossary based on your recent changes
              </div>
            )
          }
        </DialogTitle>
        <IconButton
          onClick={() => setShowModal(!showModal)}
          aria-label="close"
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        { isAddVariant && (
              <span className='text-[#1E1C80] font-poppins px-6 text-[18px] font-medium leading-normal'>Existing Variants</span>
            )
          }
        <div className="overflow-y-auto px-2 custom-scrollbar">
          {data?.dutch_word_array?.map((elem) => (
            <EditGlossaryCard
              key={elem.replacement_dutch_word}
              translationItem={elem}
              editGlossaryData={editGlossaryData}
              selectedVariant={selectedVariant}
              handleChange={handleChange}
              expanded={expanded}
            />
          ))}
        </div>
        <div className="w-full p-5 flex gap-2">
          <Button
            fullWidth
            sx={{
              border: "1px solid #D0D5DD",
              fontWeight: "600",
              fontSize: "14px",
              color: "#344054",
            }}
            variant="outlined"
            onClick={() => {
                setExpanded([selectedVariant])
                // setOccurrenceNoToEdit(0)
                setShowModal(!showModal)
                handleReplaceGlossary()
            }}
          >
            No change
          </Button>
          <Button
            disabled={isAddVariant ? isSubmitDisbaled : false}
            fullWidth
            variant="contained"
            onClick={handleSaveChanges}
            sx={{
              display:"flex",
              gap:"8px"
            }}
          >
            {loading && <CircularProgress size={15} sx={{
              color:"white",
              marginTop:'-2px'
            }}/>}
            Save Changes
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default EditGlossaryModal;