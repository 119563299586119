import React, { useState, useRef, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextAreaComponent from '../../TextArea/TextAreaComponent';
import infoIcon from '../../../assets/glossaryInfoIcon.svg';

const EditGlossaryCard = ({ translationItem, editGlossaryData,expanded ,handleChange}) => {
    const [editFor, setEditFor] = useState([]);
    const { replacement_dutch_word, instructions } = translationItem;
    const { exception, whenToApply } = instructions;
    const cardRef = useRef(null);

    const enableEdit = (mode) => {
        setEditFor((prev) => [...prev, mode]);
    };

    const onValueChange = (value, mode) => {
        editGlossaryData(replacement_dutch_word, mode, value);
    };

    const handleClickOutside = (event) => {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
            setEditFor([]);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
      <div>
        <Accordion
          expanded={expanded.includes(replacement_dutch_word)}
          onChange={handleChange(replacement_dutch_word)}
          elevation={0}
          sx={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel1-${replacement_dutch_word}content`}
            id={`panel1-${replacement_dutch_word}header`}
            sx={{
              margin: 0,
              minHeight:"35px",
              "&.Mui-expanded": {
                minHeight: "unset",
                margin: 0,
              },
              "& .MuiAccordionSummary-content": {
                margin: 0,
                "&.Mui-expanded": {
                  margin: 0,
                },
              },
              "& .MuiPaper-root": {
                // Targeting the accordion summary
                "&.Mui-expanded": {
                  // Adjust min-height when expanded
                  margin: "0px",
                },
              },
            }}
          >
            <span
              className="text-base font-normal"
              style={{ fontFamily: "Times New Roman" }}
            >
              {replacement_dutch_word}
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <div ref={cardRef} className="flex gap-4 justify-between">
              <div className="w-1/2">
                <div className="text-sm font-normal text-[#757575] mb-2 flex align-middle gap-1">
                  <div>When to apply:</div>
                  <img className="h-3 w-3 mt-1" src={infoIcon} alt="info" />
                </div>
                <TextAreaComponent
                  defaultValue={whenToApply}
                  enableEdit={() => enableEdit("whenToApply")}
                  edit={editFor.includes("whenToApply")}
                  onValueChange={(value) => onValueChange(value, "whenToApply")}
                />
              </div>
              <div className="w-1/2">
                <div className="text-sm font-normal text-[#757575] mb-2 flex align-middle gap-1">
                  <div>When to not apply:</div>
                  <img className="h-3 w-3 mt-1" src={infoIcon} alt="info" />
                </div>
                <TextAreaComponent
                  defaultValue={exception}
                  enableEdit={() => enableEdit("exception")}
                  edit={editFor.includes("exception")}
                  onValueChange={(value) => onValueChange(value, "exception")}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
}

export default EditGlossaryCard;