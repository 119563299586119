import React, { useRef, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import glossaryEditIcon from "../../assets/glossaryEditIcon.svg";
import { ReactComponent as GlossaryEditIcon } from "../../assets/glossaryEditIcon.svg";

import "./TextAreaComponent.scss";
const TextAreaComponent = ({
  defaultValue,
  edit,
  enableEdit,
  onValueChange,
}) => {
  const inputRef = useRef(null);
  const [text, setText] = useState(defaultValue);

  useEffect(() => {
    setText(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (edit) {
      inputRef.current.focus();
    }
  }, [edit]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setText(newValue);
    onValueChange(newValue);
  };

  return (
    <div
      style={{ border: edit?"1px solid #3B37FF":"1px solid #BFBFBF" }}
      className="text-xs flex flex-col h-[79px] pb-1 rounded-sm justify-between bg-white "
    >
      <textarea
        style={{fontFamily:"Poppins"}}
        ref={inputRef}
        type="text"
        maxLength={100}
        disabled={!edit}
        placeholder="Type here..."
        className={`border-none text-xs resize-none text-[#242424] hideSourceScrollBar ${edit?"h-full":"h-[60px]"}  bg-transparent w-full py-1 px-2 outline-none`}
        value={text || edit ? text : "Nothing added"}
        onChange={edit ? handleChange : undefined}
      />

      <div className="flex w-full gap-2 justify-end">
        {!edit && (
          <GlossaryEditIcon
            onClick={enableEdit}
            className="cursor-pointer text-[#999898] w-[14px] h-[14px]"
          />
        )}
      </div>
    </div>
  );
};

export default TextAreaComponent;
