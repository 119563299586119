import axios from "axios";
import apiUrls from "../api/apiUrl";
import DOMPurify from "dompurify";
const { diff_match_patch } = require("diff-match-patch");

export const addGlossarytoTheBook = async (glossaryItem) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.createGlossaryItem}`,
      glossaryItem
    );
    return data;
  } catch (error) {
    console.log("error in creating glossary");
  }
};

export const getGlossaryItem = async (translationId,language) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.getBookGlossary
      }?language=${language || "dutch"}&unique_string=${translationId}`
    );

    if (response.data.glossary_items) {
      return [];
    }

    return response.data ?? [];
  } catch (error) {
    console.log("error in getting glossary");
  }
};

export const updateGlossarytoTheBook = async (glossaryItem, glossaryId) => {
  try {
    console.log("glossaryItem", glossaryItem);
    await axios.put(
      `${
        process.env.REACT_APP_BACKEND_URL +
        apiUrls.updateBookGlossary +
        glossaryId
      }/`,
      glossaryItem
    );
  } catch (error) {
    throw error;
  }
};

export const deleteGlossaryFromTheBook = async (glossaryId) => {
  try {
    await axios.delete(
      `${
        process.env.REACT_APP_BACKEND_URL +
        apiUrls.deleteBookGlossary +
        glossaryId
      }/`
    );
  } catch (error) {
    console.log("error in deketing glossary");
  }
};

export const applySelectedGlossary = async (
  variantIndex,
  glossaryId,
  translationId,
  chapterNo,
  userEmail
) => {
  try {
    if (
      variantIndex === -1 ||
      !glossaryId ||
      !translationId ||
      !chapterNo ||
      !userEmail
    )
      return;

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.applyGlossary}`,
      {
        unique_string: translationId,
        glossary_item: glossaryId,
        variant_index: variantIndex,
        // priority_chapter_number: chapterNo,
        applied_by: userEmail,
        variant_selection_type:"full_context_type"
      }
    );
  } catch (error) {
    console.log("error in updating glossary");
  }
};

export const getGlossarySuggestionData = async (translationId) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.getGlossarySuggestion
      }?unique_string=${translationId}`
    );
    console.log("suggestion data", response?.data);
    if (response?.data?.Pending) return response.data.Pending;

    return [];
  } catch (error) {
    console.log("error in getting timestamp", error);
    return [];
  }
};

export const applySuggestedGlossary = async (glossaryPayload) => {
  try {
    if (
      !glossaryPayload.object_id ||
      !glossaryPayload?.updated_status ||
      !glossaryPayload?.priority_chapter_number
    )
      return;

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.applySuggestedGlossary}`,
      glossaryPayload
    );
  } catch (error) {
    console.log("error in updating glossary");
  }
};

export const getUpdatedTranslatedChapter = async (translationId, chapter) => {
  return axios
    .post(
      ` ${process.env.REACT_APP_BACKEND_URL}/v2/${apiUrls.getTranslation}?translationId=${translationId}&chapter=${chapter}`,
      {}
    )
    .then((response) => {
      if (response?.data?.chapter_data?.Dutch)
        return response?.data?.chapter_data?.Dutch;

      return "Error in getting translation";
    })
    .catch((error) => {
      console.log("error in getting translation");
    });
};

export const getGlossaryAffectedParagraphs = async (translationId) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.getGlossaryChangedPara
      }?unique_string=${translationId}&status=Applied`
    );
    if (response?.data) {
      let glossaryAppliedData = response?.data;
      console.log("glossaryAppliedData", glossaryAppliedData);
      let formatedData = {};
      glossaryAppliedData.forEach((appliedData) => {
        // let chapterWiseAffectedData =
        //   appliedData.individually_affected_chapter_and_para.filter(
        //     (chapterData) => chapterData.changes_data.length
        //   );

        formatedData[appliedData?.application_glossary_item?.english_word] = {
          glossaryItemDetails: appliedData?.application_glossary_item,
          translationId: appliedData?.book,
          affectedChaptersData: appliedData.individually_affected_chapter_and_para,
          status:'Applied'
        };
      });
      return formatedData;
    }

    return {};
  } catch (error) {
    console.log("error in getting timestamp", error);
    return [];
  }
};

const showDifference = (predictedText, changesText) => {
  let diff = new diff_match_patch();
  var d = diff.diff_main(predictedText, changesText);
  diff.diff_cleanupSemantic(d);
  console.log("diff seprate", d);
  const wordDiffs = [];
  for (const [diffType, diffText] of d) {
    if (diffType === diff_match_patch.DIFF_DELETE) {
      // Split deleted characters into words
      const deletedWords = diffText.split(/\s+/);
      wordDiffs.push(
        ...deletedWords.map((word) => [diff_match_patch.DIFF_DELETE, word])
      );
    } else if (diffType === diff_match_patch.DIFF_INSERT) {
      // Split inserted characters into words
      const insertedWords = diffText.split(/\s+/);
      wordDiffs.push(
        ...insertedWords.map((word) => [diff_match_patch.DIFF_INSERT, word])
      );
    } else {
      // No change at character level, so add unchanged words
      const unchangedWords = diffText.split(/\s+/);
      wordDiffs.push(
        ...unchangedWords.map((word) => [diff_match_patch.DIFF_EQUAL, word])
      );
    }
  }
  // var ds = diff.diff_prettyHtml(d);
  var ds = diff.diff_prettyHtml(d);
  const diffText = ds;
  console.log("wordDiffs", DOMPurify.sanitize(diffText));
  return DOMPurify.sanitize(diffText);
};

export const getDiffContent = (oldHtml, newHtml) => {
  const parser = new DOMParser();

  // Parse the old and new HTML strings into DOM nodes
  const oldDoc = parser.parseFromString(oldHtml, "text/html");
  const newDoc = parser.parseFromString(newHtml, "text/html");

  const oldTextPragraphs = oldDoc.querySelectorAll("body p");
  const newTextPragraphs = newDoc.querySelectorAll("body p");
  console.log("old text ", oldDoc.body.textContent);
  console.log("new text", newDoc.body.textContent);
  //  console.log("oldTextPragraphs",oldTextPragraphs)
  let modifiedHtml = newHtml;
  for (let i = 0; i < oldTextPragraphs.length; i++) {
    const oldDataParagraph = oldTextPragraphs[i];
    const newDataParagraph = newTextPragraphs[i];
    if (oldDataParagraph.textContent !== newDataParagraph.textContent) {
      const updatedDiffText = showDifference(
        oldDataParagraph.textContent,
        newDataParagraph.textContent
      );
      const modifiedPara = document.createElement("p");
      modifiedPara.innerHTML = updatedDiffText;
      // copyStyles(newDataParagraph,modifiedPara)
      modifiedPara.style.cssText = newDataParagraph.style.cssText;
      console.log("modifiedPara", modifiedPara);
      modifiedHtml = modifiedHtml.replace(
        newDataParagraph?.outerHTML,
        modifiedPara.outerHTML
      );
    }
  }

  return modifiedHtml;
};

// export const getDiffingContent = async (
//   translationId,
//   chapterNo,
//   englishWord
// ) => {
//   let promise = [];
//   promise.push(getUpdatedTranslatedChapter(translationId, chapterNo));
//   promise.push(getGlossaryAffectedParagraphs(translationId));
//   const latestData = await Promise.all(promise);
//   const latestChapter = latestData[0];
//   const glossaryAppliedData = latestData[1] ?? [];
//   console.log("glossaryAppliedData", glossaryAppliedData);
//   const currentWordData = glossaryAppliedData?.find(
//     (applicationData) =>
//       applicationData?.application_glossary_item?.english_word === englishWord
//   );

//   if (!currentWordData)
//     return { latestTranslatedData: "", diffTranslatedData: "" };

//   const currentChapterData =
//     currentWordData?.individually_affected_chapter_and_para?.[chapterNo];

//   if (!currentChapterData || !currentChapterData?.changes_data?.length)
//     return { latestTranslatedData: "", diffTranslatedData: "" };

//   //  getDiffContent()
// };


export const removeAddedSpanTags = (translateEditor) => {
  if (!translateEditor) return;
  const currentData = translateEditor?.getData();
  const container = document.createElement("div");
  container.innerHTML = currentData;
  const spans = container.querySelectorAll(`span.added`);
  if(!spans || !spans.length) return false
  spans.forEach((span) => {
    span.replaceWith(span.textContent);
  });
  return container.innerHTML;
};


export const applyGlossaryOnOccurrence=async(glossaryPayload)=>{
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.applyGlossaryOccurenceWise}`,
      glossaryPayload
    );
  } catch (error) {
    console.log("error in updating glossary");
  }
}


export const clearFindResults=(sourceEditor)=>{
  try {
     if(!sourceEditor) return ;
      const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );

    findAndReplaceEditing?.state.clear(sourceEditor.model);
    findAndReplaceEditing?.stop();
  } catch (error) {
    
  }
}



export function scrollOccurrenceContainer() {
  setTimeout(() => {

    const mainContainer = document.querySelector('.occurrencesContainer');
  
    if(!mainContainer) return ;

    // Use getBoundingClientRect to get the current scroll position relative to the viewport
    const containerRect = mainContainer?.getBoundingClientRect();
    const currentScrollPosition = mainContainer.scrollTop;
    const viewportHeight = containerRect.height;
    const documentHeight = mainContainer.scrollHeight;

    // Calculate the maximum scroll position
    const maxScroll = documentHeight - viewportHeight;

    // Find the currently highlighted element
    const highlightedElement = mainContainer.querySelector(
      ".selectedOccurrence"
    );

    if (highlightedElement) {
      const elementRect = highlightedElement?.getBoundingClientRect();
      const elementTopRelativeToContainer = elementRect.top - containerRect.top;
    
      // Get the top boundary of the viewport
      const viewportTop = window.scrollY || window.pageYOffset;
      // Get the bottom boundary of the viewport
      const viewportBottom = viewportTop + window.innerHeight;
      // Check if the element is within the visible scrollable area of the container
      const isElementInView = elementRect.bottom < containerRect.bottom && elementRect.top > containerRect.top
      console.log(
        "mainContainer",
        isElementInView,
        elementRect.top,
        containerRect.top
      )

      if (!isElementInView) {
        // Calculate the new scroll position
        let newScrollPosition = currentScrollPosition + elementTopRelativeToContainer;
        // Ensure we don't scroll past the bottom of the document
        newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScroll));

        mainContainer.scrollTo({
          top: newScrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, 100);
}