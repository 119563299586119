import React, { useEffect } from "react";

import {
  CircularProgress,
  LinearProgress,
  linearProgressClasses,
  styled,
} from "@mui/material";

function LinearProgressBar({value}) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 7,
    borderRadius: 5,
    transition: "all 1.5s ease-in-out",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      transition: "width 1.5s ease",
      backgroundColor: "#3EAF3F",
      ...theme.applyStyles("dark", {
        backgroundColor: "#3EAF3F",
      }),
    },
  }));
  useEffect(() => {
   console.log("value",value)
  }, [value])
  
  return <BorderLinearProgress variant="determinate" value={value} />;
}

export default LinearProgressBar;
